import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    byLine: {
        position: 'absolute',
        right: '16px',
    },
}));

export default function RecentComment() {
    const classes = useStyles();

    const [comment, setComment] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        const fetchComment = async () => {

            if (comment.length === 0) {

                setLoading(true);

                fetch("https://api.alachuacounty.us/paws-on-parole-api/guestbook/getRecentComment").then((res) =>
                    res.json()
                        .then((data) => {
                            setComment(data);
                            setLoading(false);
                        })
                        .catch((err) => console.log(err))
                );
            }
        }

        fetchComment();

    }, [])

    if (loading) {
        return <Typography variant="overline"> Loading.. </Typography>
    }

    return (
        <React.Fragment>
            <Typography variant="body1" paragraph>
                "{comment.message}"
                    </Typography>
            <Typography variant="body2" className={classes.byLine}> - {comment.name}, {comment.location}</Typography>
        </React.Fragment>
    )
}