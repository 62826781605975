import React from 'react';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Link from '@material-ui/core/Link'

import PetsIcon from '@material-ui/icons/Pets'

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#024977',
        },
        secondary: {
            main: '#f9d370',
        }
    },
    overrides: {

        MuiTimelineItem: {
            root: {
                minHeight: '90px',
            },
        },
        MuiTimelineContent: {
            root: {
                paddingTop: '14px',
            },
        },
    },
});

export default function AcademyTimeline() {
    return (
        <ThemeProvider theme={theme}>
            <Timeline align="alternate">
                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot color="primary" >
                            <PetsIcon />
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent style={{ textAlign: 'left' }}>
                        <Link href="/Gallery/2019" color="inherit" title='2019-2020 Graduates'>
                            2019-2020 Graduates
                    </Link>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot color="secondary" >
                            <PetsIcon />
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Link href="/Gallery/2017" color="inherit" title='2017-2018 Graduates'>
                            2017-2018 Graduates
                    </Link>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot color="primary" >
                            <PetsIcon />
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent style={{ textAlign: 'left' }}>
                        <Link href="/Gallery/2015" color="inherit" title='2015-2016 Graduates'>
                            2015-2016 Graduates
                    </Link>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot color="secondary" >
                            <PetsIcon />
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Link href="/Gallery/2013" color="inherit" title='2013-2014 Graduates'>
                            2013-2014 Graduates
                    </Link>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot color="primary" >
                            <PetsIcon />
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent style={{ textAlign: 'left' }}>
                        <Link href="/Gallery/2011" color="inherit" title='2011-2012 Graduates'>
                            2011-2012 Graduates
                    </Link>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot color="secondary" >
                            <PetsIcon />
                        </TimelineDot>
                    </TimelineSeparator>
                    <TimelineContent>
                        <Link href="/Gallery/2009" color="inherit" title='2009-2010 Graduates'>
                            2009-2010 Graduates
                    </Link>
                    </TimelineContent>
                </TimelineItem>
            </Timeline>
        </ThemeProvider>
    );
}
