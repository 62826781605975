import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
    flexList: {
        display: 'flex',
        width: '100%',
        //justifyContent: 'space-between',
        justifyContent: 'center',
        flexWrap: 'wrap'
    },
}));

export default function Sponsors(props) {
    const classes = useStyles();

    return (
        <div className={classes.flexList}>
            {
                props.list.map((item, index) => (
                    <Link href={item.link} target="_blank" color="inherit" title={item.title}>
                        <img src={item.logo} style={{ maxWidth: '100px' }} alt={item.title}></img>
                        <Typography variant="caption" style={{ display: 'block' }}>
                            Click to see website
                        </Typography>
                    </Link>
                ))
            }
        </div>
    )
}