import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';

import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { useHistory } from "react-router-dom";

function FeedbackAlert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
}));

export default function GuestForm(props) {

    const {
        register,
        handleSubmit,
        errors,
        reset,
    } = useForm();

    const [state, setState] = useState({
        message: "",
        location: "",
        name: "",
        email: "",
    });
    const classes = useStyles();

    const [submitStatus, setSubmitStatus] = useState(null);
    const [feedbackOpen, setFeedbackOpen] = useState(false);

    const handleFeedbackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setFeedbackOpen(false);

    };

    const onGuestFormSubmit = (data) => {
        //console.log(data);

        const body = {
            name: data.name,
            location: data.location,
            message: data.message,
        }

        if (data.email !== "") {
            body.email = data.email;
        }

        fetch(
            ' https://api.alachuacounty.us/paws-on-parole-api/guestbook/addGuestBookComment',
            {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify(body),
            }
        )
            .then((response) => {

                setSubmitStatus(response.status);
                reset({
                    message: "",
                    location: "",
                    name: "",
                    email: "",
                });

            })
            /* .then((data) => {
                 //data = monday itemID
                 setSubmitStatus(200);
 
                 reset({
                     message: "",
                     location: "",
                     name: "",
                     email: "",
                 });
 
             })*/
            .catch((err) => {
                console.log(err);
                setSubmitStatus(500);
            });

        setFeedbackOpen(true);

    }

    const handleChange = (event) => {
        const name = event.target.name;
        setState({
            ...state,
            [name]: event.target.value,
        });
    };

    const history = useHistory();

    const routeChange = () => {
        let path = `GuestBook`;
        history.push(path);
    }

    return (
        <form onSubmit={handleSubmit(onGuestFormSubmit)} >
            {/*console.log(state)*/}
            <FormGroup>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            name='name'
                            label='Name'
                            id='name'
                            placeholder='Name'
                            variant='outlined'
                            size='small'
                            fullWidth={true}
                            onChange={handleChange}
                            autoComplete='on'
                            inputProps={{
                                "aria-required": 'true',
                            }}
                            inputRef={register({
                                required: true
                            })}>
                        </TextField>
                        {errors.name &&
                            errors.name.type ===
                            'required' && (
                                <Alert severity='error' id='NameError'>Required</Alert>
                            )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            name='location'
                            label='Location'
                            id='location'
                            placeholder='City, State'
                            variant='outlined'
                            size='small'
                            fullWidth={true}
                            onChange={handleChange}
                            autoComplete='on'
                            inputProps={{
                                "aria-required": 'true',
                            }}
                            inputRef={register({
                                required: true
                            })}>
                        </TextField>
                        {errors.location &&
                            errors.location.type ===
                            'required' && (
                                <Alert severity='error' id='LocationError'>Required</Alert>
                            )}
                    </Grid>
                    {props.withContact && <Grid item xs={12}>
                        <TextField
                            name='email'
                            label='Email'
                            id='email'
                            placeholder='Optionally provide your email so our team can get in touch with you'
                            variant='outlined'
                            size='small'
                            fullWidth={true}
                            onChange={handleChange}
                            autoComplete='on'
                            inputProps={{
                                "aria-required": 'true',
                            }}
                            inputRef={register({
                                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            })}>
                        </TextField>
                        {errors.email &&
                            errors.email.type === 'pattern' && (
                                <Alert severity='error' id='emailValidationError'>Invalid Email </Alert>
                            )}
                    </Grid>
                    }
                    <Grid item xs={12}>
                        <TextField
                            name="message"
                            label="Message"
                            id='message'
                            multiline
                            rows={4}
                            defaultValue=""
                            placeholder='Your Message'
                            variant="outlined"
                            fullWidth={true}
                            onChange={handleChange}
                            inputProps={{
                                "aria-required": 'true',
                            }}
                            inputRef={register({
                                required: true
                            })}
                        />
                        {errors.message &&
                            errors.message.type ===
                            'required' && (
                                <Alert severity='error' id='MessageError'>Required</Alert>
                            )}
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: 'left' }}>
                        <div className={classes.root}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit">
                                Submit
                            </Button>
                        </div>
                    </Grid>
                    {!props.withContact && <Grid item xs={8} style={{ textAlign: 'right' }}>
                        <div className={classes.root}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={routeChange}>
                                View GuestBook
                                </Button>
                        </div>
                    </Grid>
                    }
                    {
                        submitStatus === 200 && (
                            <Snackbar open={feedbackOpen} autoHideDuration={6000} onClose={handleFeedbackClose}>
                                <FeedbackAlert onClose={handleFeedbackClose} severity="success">
                                    Thank you, your message has been recorded!
                                </FeedbackAlert>
                            </Snackbar>
                        )
                    }
                    {
                        submitStatus !== null && submitStatus !== 200 && (
                            <Snackbar open={feedbackOpen} autoHideDuration={6000} onClose={handleFeedbackClose}>
                                <FeedbackAlert onClose={handleFeedbackClose} severity="error">
                                    Oops, looks like we ran into a problem! Please try again.
                                </FeedbackAlert>
                            </Snackbar>
                        )
                    }
                </Grid>
            </FormGroup>
        </form>
    )
}