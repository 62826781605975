import React from "react";
import Link from '@material-ui/core/Link';

import adoptionApp from '../../documents/adoption-application.pdf';
import adoptionProcedures from '../../documents/adoption-procedures.pdf';

import {handoutItems} from './handoutItems'

//make Contact Us and Home internal link?; no target=_blank; Link to page of paws on parole adoption page
export const adoptBlurb = {
    "important": {
        "title": 'IMPORTANT!!!',
        "content": (<><p>Please read instructions carefully and do not skip any steps!</p>
    
            <p>There are requirements that must be met in order to adopt from Alachua County Animal Services.</p>
        
            <p> Included in the instructions below are 2 applications. Both must be filled out in order to adopt a Paws on Parole dog. 
                The procedure for submitting each application is different.</p>
        
            <p>If you have any questions please <Link href={"/Contact"} color="inherit" underline="always" title={'Contact Page'}>CONTACT US</Link> or 
            call Hilary Hynes at 352-213-1241.</p> </>),
        "className": 'important'
    },
    "steps": {
        "title": 'Steps',
        "content": (<>
            <p>Thanks for your interest in adopting a Paws on Parole dog. We look forward to helping you find the perfect fit for your family! 
                Here is the process you will need to follow...</p>
            <ol>
                <li>Read and/or print a copy of <Link href={adoptionProcedures} color="inherit" target="_blank" underline="always" title={'Alachua County Animal Services Adoption Procedures'}>
                    Alachua County Animal Services Adoption Procedures.</Link></li>
                <p></p>
                <li>Download, print, and fill out the <Link href={adoptionApp} color="inherit" target="_blank" underline="always" title={'Alachua County Animal Services Adoption Application'}>
                    Alachua County Animal Services Adoption Application</Link> and bring it with you when you come to the first outing. You will also need to provide 
                    your Drivers License and proof of rabies vaccinations for all currently owned dogs and cats. 
                    If you are unable to attend, or if you missed the first outing you can fax, mail or hand deliver the application 
                    to Animal Services. Their address is: 3400 NE 53rd Ave, Gainesville, FL 32609. Their Fax number is: (352) 955-2542.</li>
                <p></p>
                <li>Fill out the <Link href={"/AdoptionApplication"} color="inherit" target="_blank" underline="always" title={'Paws on Parole Adoption Application'}>
                    Paws on Parole Adoption Application.</Link> This application is filled out and submitted online. 
                    (Note: You must complete a new Paws on Parole application if you did not get the dog of your choice from a 
                    previous academy or if you plan to add another Paws on Parole dog to your family.)</li>
            </ol></>),
        "className": 'primary'
    },
    "meetAndGreet": {
        "title": 'Meet and Greet',
        "content": (<><p>Next, make plans to attend our first “Meet and Greet” to meet us and the dogs. Go to our <Link href={"/"} color="inherit" underline="always" title={'Home Page'}>Home</Link> page 
        for outing dates and locations.</p>
    
            <p>When you arrive at the “Meet and Greet” please go to the Paws on Parole table and sign in on the sheet provided. There are usually at least 2 adoption counselors 
                at the outing and you will need to talk to each one during your visit. You can do this before, during or after your time with a dog. The outing can get a bit hectic 
                so you may need to seek out the counselors in order to talk to them.</p>
        
            <p>We are looking for the “perfect fit” for our dogs… If there is more than one ‘‘perfect fit,’’ the dog will be adopted to the family who applied first.</p>
        
            <p>Very often we find the perfect fit during the first meet and greet. If that is the case you will be notified within the next few days to go to Animal Services and 
                complete the adoption process. The adoption fee is $40.00 and includes spay/neuter, microchip, all immunizations, flea and heartworm prevention, and a bag of food.</p>
            
            <p>You will then be expected to attend the 2nd outing to work with our training instructors, spend time with your new family member and pick up your training manual 
                (you will be asked for a $10.00 donation to help us purchase more manuals).</p>
        
            <p>Before this outing please download, print, read and save the "Dog Care and Training Handouts for Adopters" files in the links below so you will have a better understanding of our training.</p>
                </>)
    },
    "graduationDay": {
        "title": 'Graduation Day',
        "content": (<><p>On Graduation Day you will be able to take your dog home as soon as the graduation exercises have ended. If for some reason you cannot attend graduation please let us know so other arrangements can be made.</p>
    
            <p>Your dog will NOT come with a leash and collar. You must bring your own or purchase from us the day you pick up your dog. </p>
        
            <p>IMPORTANT! In order to attend Graduation you will be asked to submit proper identification information to us at least 2 weeks in advance.</p>
                </>),
        "className": 'primary'
    },
    "handouts": { 
        "title": 'Downloadable Dog Care and Training Handouts for Adopters',
        "content": (<><ul>
        {
            handoutItems.map((item, index) => (<li> 
                <Link href={item.link} target='_blank' underline="always" color="inherit" title={item.title}>
                    {item.title}
                </Link>
                <p></p>
            </li>))
        }
       </ul> </>),
        "className": 'primary'
    },
};