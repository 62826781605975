import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import Comment from './comment';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    pagination: {
        '& .MuiPagination-ul': {
            justifyContent: 'center',
        },
    },
}));

export default function GuestComments(props) {
    const classes = useStyles();
    const [comments, setComments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalComments, setTotalComments] = useState(0);
    const pageLimit = props.pageLimit;
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentComments, setCurrentComments] = useState([]);



    useEffect(() => {

        const fetchComments = async () => {

            if (comments.length === 0) {

                setLoading(true);

                fetch("https://api.alachuacounty.us/paws-on-parole-api/guestbook/getGuestBookComments").then((res) =>
                    res.json()
                        .then((data) => {
                            setComments(data);
                            setLoading(false);
                        })
                        .catch((err) => console.log(err))
                );
            }

            setTotalComments(comments.length);
            setTotalPages(Math.ceil(totalComments / pageLimit));
            const indexOfLastComment = currentPage * pageLimit;
            const indexOfFirstComment = indexOfLastComment - pageLimit;
            setCurrentComments(comments.slice(indexOfFirstComment, indexOfLastComment));

        }

        fetchComments();

    }, [comments, comments.length, totalComments, pageLimit, totalPages, currentPage])

    const handleChange = (event, value) => {
        setCurrentPage(value);
        const indexOfLastComment = currentPage * pageLimit;
        const indexOfFirstComment = indexOfLastComment - pageLimit;
        setCurrentComments(comments.slice(indexOfFirstComment, indexOfLastComment));
    };

    if (loading) {
        return <Typography variant="overline"> Loading.. </Typography>
    }

    return (
        <div className={classes.root}>
            {
                currentComments.map((item, index) => (
                    <Comment details={item} key={`comment-${index}`} />
                ))
            }
            <Pagination
                color="primary"
                className={classes.pagination}
                count={totalPages}
                page={currentPage}
                onChange={handleChange} />
        </div>
    );
}