import React from 'react'
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';

function Copyright() {
  return (
    <>
      <Typography variant="body2" paragraph>{'© Alachua County'} </Typography>
      <Typography variant="body2" >

        <Link color="inherit" underline='always' target="_blank" href="https://alachuacounty.us/Depts/EO/Pages/Website-Accessibility.aspx" title="View Alachua County's Website Accessibility Policy And Procedures">
          View Alachua County's Website Accessibility Policy And Procedures
      </Link>
      </Typography>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor:
      theme.palette.primary.main,
    color: '#FFFFFF',
    flexGrow: '0',
    flexShrink: '1',
    flexBasis: 'auto',
  },
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container maxWidth="sm">
        <Copyright />
      </Container>
    </footer>
  );
}
