import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import clsx from 'clsx'
import moment from 'moment';
import purple from '@material-ui/core/colors/purple';
import amber from '@material-ui/core/colors/amber';
import deepPurple from '@material-ui/core/colors/deepPurple';
import indigo from '@material-ui/core/colors/indigo';
import teal from '@material-ui/core/colors/teal';
import cyan from '@material-ui/core/colors/cyan';
import lightGreen from '@material-ui/core/colors/lightGreen';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    avatarLarge: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        margin: 'auto',
        [theme.breakpoints.down('xs')]: {
            width: '0px',
            height: '0px',
            padding: '0px',
        },
    },
    noPadding: {
        [theme.breakpoints.down('xs')]: {
            padding: '0px !important',
        },
    },
    flushRight: {
        textAlign: 'right',
    },
    inline: {
        //display: 'inline-block',
    },
    purple: {
        backgroundColor: purple[200],
    },
    amber: {
        backgroundColor: amber[200],
    },
    deepPurple: {
        backgroundColor: deepPurple[200],
    },
    indigo: {
        backgroundColor: indigo[200],
    },
    teal: {
        backgroundColor: teal[200],
    },
    cyan: {
        backgroundColor: cyan[200],
    },
    lightGreen: {
        backgroundColor: lightGreen[200],
    },
}));

export default function Comment(props) {
    const classes = useStyles();

    const colors = [classes.purple, classes.amber, classes.deepPurple, classes.indigo, classes.teal, classes.cyan, classes.lightGreen];
    var min = 1;
    var max = colors.length;
    var rand = min + (Math.random() * (max - min));
    rand = Math.round(rand);

    return (

        <Grid container spacing={3}>
            <Grid item sm={2} className={classes.noPadding}>
                <Avatar className={clsx(classes.avatarLarge, colors[rand - 1])} />
            </Grid>
            <Grid item xs={12} sm={10}>
                <Grid container spacing={1}>
                    <Grid item xs={8} sm={8} style={{ textAlign: 'left' }}>
                        <Typography variant="h5" component="p" className={classes.inline} color="primary"> {props.details.name} </Typography>
                        <Typography variant="overline" style={{ whiteSpace: 'nowrap' }}> {props.details.location} </Typography>
                    </Grid>
                    <Grid item xs={4} sm={4} className={classes.flushRight}>
                        <Typography variant="overline" style={{ textTransform: 'capitalize', whiteSpace: 'nowrap' }}>
                            {moment(props.details.date).format("MMM D YYYY")}
                        </Typography>
                        <Typography variant="overline" style={{ whiteSpace: 'nowrap' }}>
                            {moment(props.details.date).format(" hh:mm A")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2" gutterBottom style={{ textAlign: 'left' }}>
                            {props.details.message}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}