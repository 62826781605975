export const photos = [
    {
        src: "https://imagizer.imageshack.com/img924/9270/MNcox0.jpg",
        width: 4,
        height: 3
    },
    {
        src: "https://imagizer.imageshack.com/img923/2976/IMGy7x.jpg",
        width: 1,
        height: 1
    },
    {
        src: "https://imagizer.imageshack.com/img924/1760/OUxDkf.jpg",
        width: 3,
        height: 4
    },
    {
        src: "https://imagizer.imageshack.com/img923/1513/p3h2WV.jpg",
        width: 3,
        height: 4
    },
    {
        src: "https://imagizer.imageshack.com/img922/7301/97QUF9.jpg",
        width: 3,
        height: 4
    },
    {
        src: "https://imagizer.imageshack.com/img924/3172/aJPNmn.jpg",
        width: 4,
        height: 3
    },
    {
        src: "https://imagizer.imageshack.com/img923/9314/W8usq4.jpg",
        width: 3,
        height: 4
    },
    {
        src: "https://imagizer.imageshack.com/img924/7200/2gI7G6.jpg",
        width: 4,
        height: 3
    },
    {
        src: "https://imagizer.imageshack.com/img922/1336/jUV72B.jpg",
        width: 3,
        height: 4
    },
    {
        src: "https://imagizer.imageshack.com/img922/3898/cDL8pp.jpg",
        width: 4,
        height: 3
    },
    {
        src: "https://imagizer.imageshack.com/img924/6889/kFHgft.jpg",
        width: 3,
        height: 4
    },
    {
        src: "https://imagizer.imageshack.com/img922/4859/MYJmlh.jpg",
        width: 4,
        height: 3
    },
    {
        src: "https://imagizer.imageshack.com/img924/4160/mzWtUL.jpg",
        width: 3,
        height: 4
    },
    {
        src: "https://imagizer.imageshack.com/img924/2999/fYGwB6.jpg",
        width: 4,
        height: 3
    },
    {
        src: "https://imagizer.imageshack.com/img924/3104/yzLlP7.jpg",
        width: 3,
        height: 4
    },
    {
        src: "https://imagizer.imageshack.com/img922/9738/XpMHKQ.jpg",
        width: 3,
        height: 4
    },
    {
        src: "https://imagizer.imageshack.com/img922/8797/AHKl8o.jpg",
        width: 3,
        height: 4
    },
    {
        src: "https://imagizer.imageshack.com/img924/6636/rL2CMT.jpg",
        width: 4,
        height: 3
    },
    {
        src: "https://imagizer.imageshack.com/img924/2584/9Aw7zW.jpg",
        width: 3,
        height: 4
    }
];
