//import logo from './logo.svg';
import './App.css';
import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import HomePage from "./pages/Home/home"
import AboutPage from "./pages/About/about"
import GuestBookPage from './pages/GuestBook/guestbook'
import AcademyGalleryPage from './pages/AcademyGallery/gallery'
import OurDogsPage from './pages/OurDogs/ourDogs'
import TestPage from './pages/Test'
import ContactPage from './pages/Contact/contact'
import AdoptionApplicationPage from './pages/AdoptionApplication/application'
import HappyEndingsPage from './pages/HappyEndings/happyEndings'
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import LinksPage from './pages/Links/links'
import AdoptPage from './pages/Adopt/adopt'

import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#024977',
      light: '#CFE3E3',
    },
    secondary: {
      main: '#fff3d3',
    }
  },
  typography:{
    body1: {
      color: 'rgba(0, 0, 0, 0.66)',
    },
    caption:{
      color: 'rgba(0, 0, 0, 0.66)',
    }
  }
});


function App() {

  const pageTitles = [
    "Paws On Parole",
    "About Us",
    "GuestBook",
    "Gallery",
    "Our Dogs",
    "Contact",
    "Adoption Application",
    "Happy Endings",
    "Links",
    "Adopt"
  ];


  return (
    <MuiThemeProvider theme={theme}>
      <div className="App">
        <Navigation></Navigation>
        <Grid
          container
          direction={'row'}
          id="main"
        >
          <Grid item xl={12} md={12} sm={12} xs={12}>
            <main>
              <Router>
                <Switch>
                  <Route exact path="/" render={() => { document.title = pageTitles[0]; return <HomePage /> }} />
                  <Route exact path="/About" render={() => { document.title = pageTitles[1]; return <AboutPage /> }} />
                  <Route exact path="/Guestbook" render={() => { document.title = pageTitles[2]; return <GuestBookPage /> }} />
                  <Route path="/Gallery/:batchId" render={(props) => { document.title = pageTitles[3]; return <AcademyGalleryPage {...props} /> }} />
                  <Route exact path="/OurDogs" render={() => { document.title = pageTitles[4]; return <OurDogsPage /> }} />
                  <Route exact path="/Test" render={() => { document.title = pageTitles[0]; return <TestPage /> }} />
                  <Route exact path="/Contact" render={() => { document.title = pageTitles[5]; return <ContactPage /> }} />
                  <Route exact path="/AdoptionApplication" render={() => { document.title = pageTitles[6]; return <AdoptionApplicationPage /> }} />
                  <Route exact path="/HappyEndings" render={() => { document.title = pageTitles[7]; return <HappyEndingsPage /> }} />
                  <Route exact path="/Links" render={() => { document.title = pageTitles[8]; return <LinksPage /> }} />
                  <Route exact path="/Adopt" render={() => { document.title = pageTitles[9]; return <AdoptPage /> }} />
                </Switch>
              </Router>
            </main>
          </Grid>
        </Grid>
        <Footer></Footer>
      </div>
    </MuiThemeProvider>
  );
}

export default App;
