import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PhotoGallery from '../../components/PhotoGallery/'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx'
//import { batches } from './batches'
const batches = require("./batches.json")
//import { academies } from './previousAcademies';
//import { album } from './getBatches'
const academy_2009 = require('./academies_2009.json');
const academy_2011 = require('./academies_2011.json');
const academy_2013 = require('./academies_2013.json');
const academy_2015 = require('./academies_2015.json');
const academy_2017 = require('./academies_2017.json');
const academy_2019 = require('./academies_2019.json');

// const obj1 = JSON.parse(academy_TjVw7);
// const obj2 = JSON.parse(academy_DnVw7);

//const academies = require('./academies_Ash.json')

var academies = {};

/*{
    ...academy_DnVw7,
    ...academy_TjVw7
};*/

//console.log(JSON.stringify(academies));

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(4),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        paddingLeft: '15%',
        paddingRight: '15%',
        [theme.breakpoints.up('md')]: {
            paddingLeft: '20%',
            paddingRight: '20%',
        },
    },
    noTopGutter: {
        paddingTop: '0',
    },
    academyNumber: {
        color: '#585858',
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
}));


const AcademyGallery = ({ match }) => {

    const { params: { batchId } } = match;
    const classes = useStyles();
    academies = eval('academy_'+batchId);

    if (batches[batchId] === undefined) {
        return (
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Paper square={true} elevation={0} className={classes.paper}>
                        <Typography variant="h4" component="h1" color="primary">
                            Page not Found..
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
        )
    }

    const academyList = batches[batchId].albumId; //album id's instead of indexes

    return (
        <>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Paper square={true} elevation={0} className={classes.paper}>
                        <Typography variant="h4" component="h1" color="primary">
                            {batches[batchId].title}
                        </Typography>
                        <Typography variant="body1">
                            (Click on any photo to enlarge)
                    </Typography>
                    </Paper>
                </Grid>
            </Grid>
            {
                academyList.map((academyId) => (
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <Paper square={true} elevation={0} className={classes.paper}>
                                <Typography variant="h6" component="h2" color="primary" className={classes.academyNumber}>
                                    {academies[academyId].academyNumbers}
                                </Typography>
                                <Typography variant="h6" component="h3" color="primary">
                                    "{academies[academyId].academyName}"
                        </Typography>
                                <Typography variant="body1" >
                                    Graduated on {academies[academyId].academyGraduationDate}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper square={true} elevation={0} className={clsx(classes.paper, classes.noTopGutter)}>
                                <PhotoGallery photos={academies[academyId].photos} />
                            </Paper>
                        </Grid>
                    </Grid>
                ))
            }
        </>
    )
}

export default AcademyGallery;