import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        textAlign: 'center',
        color: theme.palette.text.secondary,
        paddingLeft: '15%',
        paddingRight: '15%',
        [theme.breakpoints.up('md')]: {
            paddingLeft: '20%',
            paddingRight: '20%',
        },
    },
    imageCaption: {
        maxWidth: '75%',
        margin: "auto",
        fontWeight: 'bold',
    },
    imgPOP: {
        maxWidth: "100%",
    },
    imgTitle: {
        fontWeight: 'bold',

    }
}));



export default function ImageWithCaption(props) {
    const classes = useStyles();

    return (
        /*Each Picture should be its own grid item */
        /* Grid -> grid item -> paper -> img, h2, p 
        */
        <Grid container spacing={0}>
            <Grid item xs={12}>
                {props.title !== '' && (
                    <Typography variant="h6" component="h2" color="primary" paragraph={true} className={classes.imgTitle}>
                        {props.title}
                    </Typography>
                )}
                <img src={props.image} alt={props.caption} className={classes.imgPOP} />
                <Typography variant="body1" component="p" color="primary" align="center" paragraph={true} className={classes.imageCaption}>
                    {props.caption}
                </Typography>
            </Grid>
        </Grid >
    )
}