import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';

import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function FeedbackAlert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
}));

export default function ContactForm() {

    const {
        register,
        handleSubmit,
        errors,
        reset,
    } = useForm();

    const [state, setState] = useState({
        messagec: "",
        namec: "",
        emailc: "",
    });
    const classes = useStyles();

    const [submitStatus, setSubmitStatus] = useState(null);
    const [feedbackOpen, setFeedbackOpen] = useState(false);

    const handleFeedbackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setFeedbackOpen(false);
    };

    const onContactFormSubmit = (data) => {
        //console.log(data);
        //TODO : post data

        const body = {
            name: data.namec,
            email: data.emailc,
            message: data.messagec
        }
        fetch(
            ' https://api.alachuacounty.us/paws-on-parole-api/contact/',
            {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify(body)
            }
        )
            .then((response) => {
                setSubmitStatus(response.status);
                reset({
                    messagec: "",
                    namec: "",
                    emailc: "",
                });
            })
            /* .then((data) => {
                 setSubmitStatus(200);
                 console.log("we got data")
                 console.log(data);
 
                 reset({
                     messagec: "",
                     namec: "",
                     emailc: "",
                 });
 
             })*/
            .catch((err) => {
                console.log(err);
                setSubmitStatus(500);
            });

        setFeedbackOpen(true);

    }

    const handleChange = (event) => {
        const name = event.target.name;
        setState({
            ...state,
            [name]: event.target.value,
        });
    };

    return (
        <form onSubmit={handleSubmit(onContactFormSubmit)} >
            {/*console.log(state)*/}
            <FormGroup>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            name='namec'
                            label='Name'
                            id='name'
                            placeholder='Name'
                            variant='outlined'
                            size='small'
                            fullWidth={true}
                            onChange={handleChange}
                            autoComplete='on'
                            inputProps={{
                                "aria-required": 'true',
                            }}
                            inputRef={register({
                                required: true
                            })}>
                        </TextField>
                        {errors.namec &&
                            errors.namec.type ===
                            'required' && (
                                <Alert severity='error' id='nameError'>Required</Alert>
                            )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            name='emailc'
                            label='Email'
                            id='email'
                            placeholder='Email'
                            variant='outlined'
                            size='small'
                            fullWidth={true}
                            onChange={handleChange}
                            autoComplete='on'
                            inputProps={{
                                "aria-required": 'true',
                            }}
                            inputRef={register({
                                required: true,
                                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            })}>
                        </TextField>
                        {errors.emailc &&
                            errors.emailc.type ===
                            'required' && (
                                <Alert severity='error' id='emailError'>Required</Alert>
                            )}
                        {errors.emailc &&
                            errors.emailc.type === 'pattern' && (
                                <Alert severity='error' id='emailValidationError'>Invalid Email </Alert>
                            )}
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="messagec"
                            label="Message"
                            id='message'
                            multiline
                            rows={4}
                            defaultValue=""
                            placeholder='Your Message'
                            variant="outlined"
                            fullWidth={true}
                            onChange={handleChange}
                            inputProps={{
                                "aria-required": 'true',
                            }}
                            inputRef={register({
                                required: true
                            })}
                        />
                        {errors.messagec &&
                            errors.messagec.type ===
                            'required' && (
                                <Alert severity='error' id='messageError'>Required</Alert>
                            )}
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: 'left' }}>
                        <div className={classes.root}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit">
                                Submit
                            </Button>
                        </div>
                    </Grid>
                    {
                        submitStatus === 200 && (
                            <Snackbar open={feedbackOpen} autoHideDuration={6000} onClose={handleFeedbackClose}>
                                <FeedbackAlert onClose={handleFeedbackClose} severity="success">
                                    Thank you, we've received your message!
                                </FeedbackAlert>
                            </Snackbar>
                        )
                    }
                    {
                        submitStatus !== null && submitStatus !== 200 && (
                            <Snackbar open={feedbackOpen} autoHideDuration={6000} onClose={handleFeedbackClose}>
                                <FeedbackAlert onClose={handleFeedbackClose} severity="error">
                                    Oops, looks like we ran into a problem! Please try again.
                                </FeedbackAlert>
                            </Snackbar>
                        )
                    }
                </Grid>
            </FormGroup>
        </form>
    )
}