import socializeDog1 from '../../documents/1-socialize-my-new-dog.pdf';
import socializeDog2 from '../../documents/2-socializing-your-dog.pdf';
import howDogsLearn from '../../documents/how-dogs-learn.pdf';
import positiveTraining from '../../documents/positive-training-principles-and-tips.pdf';
import aboutPunishment from '../../documents/about-punishment.pdf';
import k9Vocab from '../../documents/k-9-vocabulary-reference.pdf';
import workToEarn from '../../documents/work-to-earn.pdf';
import houseTraining from '../../documents/housetraining-and-your-paws-on-parole-dog.pdf';
import crateTraining from '../../documents/crate-training.pdf';
import choosingDogFood from '../../documents/choosing-your-dogs-food.pdf';
import looseLeashWalking from '../../documents/loose-leash-walking.pdf';

export const handoutItems = [
    {title: 'Socializing My New Dog Part 1', link: socializeDog1}, 
    {title: 'Socializing My New Dog Part 2', link: socializeDog2},
    {title: 'How Dogs Learn', link: howDogsLearn},
    {title: 'Positive Training Principles and Tips', link: positiveTraining},
    {title: 'About Punishment', link: aboutPunishment}, 
    {title: 'K-9 Vocabulary Reference', link: k9Vocab}, 
    {title: 'Work to Earn', link: workToEarn}, 
    {title: 'Housetraining', link: houseTraining},
    {title: 'Crate Training', link: crateTraining},
    {title: 'Choosing Your Dog Food', link: choosingDogFood},
    {title: 'Loose Leash walking', link: looseLeashWalking}
];