import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import GuestComments from '../../components/GuestBook/pages';
import Form from '../../components/GuestBook/form'
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(4),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        paddingLeft: '15%',
        paddingRight: '15%',
        [theme.breakpoints.up('md')]: {
            paddingLeft: '20%',
            paddingRight: '20%',
        },
    },
    yellow: {
        background: theme.palette.secondary.main,
    },
}));


export default function HomePage() {
    const classes = useStyles();

    const [showForm, setShowForm] = useState(false);

    const handleClick = (event, value) => {
        setShowForm(!showForm);
    };

    return (
        <div>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Paper square={true} elevation={0} className={classes.paper}>
                        <Typography variant="h4" component="h1" color="primary"> Our GuestBook</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper square={true} elevation={0} className={classes.paper}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleClick}>
                            Add Message
                        </Button>
                    </Paper>
                    {showForm &&
                        <Paper square={true} elevation={0} className={classes.paper}>
                            <Form withContact={true} />
                        </Paper>
                    }
                </Grid>
                <Grid item xs={12}>
                    <Paper square={true} elevation={0} className={classes.paper}>
                        <GuestComments pageLimit="4" />
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}