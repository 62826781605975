import React from "react";
import AdoptionForm from '../../components/AdoptionForm'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(4),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        paddingLeft: '15%',
        paddingRight: '15%',
        [theme.breakpoints.up('md')]: {
            paddingLeft: '20%',
            paddingRight: '20%',
        },
    },
    paperForm: {
        padding: theme.spacing(4),
        textAlign: 'left',
        paddingLeft: '5%',
        paddingRight: '5%',
        color: theme.palette.text.secondary,
        [theme.breakpoints.up('md')]: {
            paddingLeft: '15%',
            paddingRight: '15%',
        },

    }
}));

export default function AdoptionApplication() {
    const classes = useStyles();
    return (
        <Grid container spacing={0}>
            <Grid item xs={12}>
                <Paper elevation={0} className={classes.paper}>
                    <Typography variant="h4" component="h1" color="primary" paragraph>
                        Adoption Application
                    </Typography>
                    <Typography variant="body1" component="p">
                        Please take the time to fill out this application as completely as possible.The more information you provide to us, the better job we can do in finding the best fit for your family.
                    </Typography>
                    <Typography variant="body1" component="p">
                        We may receive multiple applications on a single dog. The final decisions as to placement are based on the best interests of our dogs as well as the date of application. Placement decisions are generally not made until after the 4th week of the training academy.
                    </Typography>
                    <Typography variant="body1" component="p">
                        Please understand that even though these dogs have gone through our training program and have passed the AKC Good Citizen test they need continued training to maintain their Good Citizen behavior. The dogs also need an adjustment period in their new home. They need time to adjust to you, to their new environment, new smells, and other pets you may already have in your household. If you experience any unexpected behaviors or other issues please do not just return your dog. Please contact the Paws Team immediately. We are more than willing to assist you with any concerns you may have.
                    </Typography>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper elevation={0} className={classes.paperForm}>
                    <AdoptionForm />
                </Paper>
            </Grid>
        </Grid>
    )
}