import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Hero from '../../components/Hero';
import heroImage from '../../img/hero.jpg';
import storyImage from '../../img/story.png';
//import logo1 from '../../img/Sponsors/EarthPetsLogo.gif';
//import logo2 from '../../img/Sponsors/frommLogo.png';
//import logo3 from '../../img/Sponsors/ggdfaLogo.jpg';
//import logo4 from '../../img/Sponsors/NewberryAnimalHospitalLogo.jpg';
//import logo5 from '../../img/Sponsors/doghairLogo.jpg';
import logo6 from "../../img/Sponsors/ACSO-Logo.png";
import FeaturedStory from '../../components/FeaturedStory'
import Sponsors from '../../components/Sponsors'
import GuestBook from '../../components/GuestBook'
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(4),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        paddingLeft: '15%',
        paddingRight: '15%',
        [theme.breakpoints.up('md')]: {
            paddingLeft: '20%',
            paddingRight: '20%',
        },
    },
    yellow: {
        background: theme.palette.secondary.main,
    },
}));

const sponsorList = [
    //{ logo: logo1, link: "/", title: "" },
    //{ logo: logo2, link: "/", title: "" },
    //{ logo: logo3, link: "/", title: "" },
    //{ logo: logo4, link: "/", title: "" },
    //{ logo: logo5, link: "/", title: "" },
    { logo: logo6, link: "https://acso.us/organization/department-of-the-jail/", title: "" },
]

export default function HomePage() {
    const classes = useStyles();

    return (
        <div>
            <Hero image={heroImage} />
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Paper square={true} elevation={0} className={classes.paper}>
                        <Typography variant="body1" paragraph={true}>
                            Located in Gainesville, Florida, our prison-based dog training programs
                            pair adoptable dogs from Alachua County Animal Services with inmates
                            from the Florida Dept. of Corrections and the Alachua County Jail.
                        </Typography>
                        <Typography variant="body1" >
                            The dogs live with and are trained by the inmates for 8 weeks and learn the basic
                            skills necessary to pass the American Kennel Club's Canine Good Citizen test.
                            The adoption fee is $40.00 and includes spay/neuter, microchip, and vaccines.
                            </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper square={true} elevation={0} className={classes.paper}>
                        <Typography variant="h5" component="h2" color="primary" style={{ textTransform: 'uppercase', marginBottom: '16px' }}>
                            Featured Story
                    </Typography>
                        <FeaturedStory
                            title="Alachua County Jail inmates train dogs to become adoptable"
                            caption=""
                            image={storyImage}
                            imageCaption=""
                            link="https://mycbs4.com/news/local/she-was-my-happiness-alachua-county-jail-inmates-train-dogs-to-become-adoptable">
                        </FeaturedStory>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper square={true} elevation={0} className={clsx(classes.paper, classes.yellow)} >
                        <Typography variant="h5" component="h2" color="primary" style={{ textTransform: 'uppercase', marginBottom: '16px' }}>
                            Our Partners
                    </Typography>
                        <Sponsors list={sponsorList}></Sponsors>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <GuestBook></GuestBook>
                </Grid>
            </Grid>
        </div >
    );
};
