import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

    container: {
        position: 'relative',
    },
    paper: {
        position: 'absolute',
        top: '35%',
        left: '65px',
        padding: '14px',
        color: '#FFFFFF',
        background: 'rgba(0,0,0,0.5)',
        maxWidth: '300px',
    },
}))

export default function Hero(props) {
    const classes = useStyles();

    return (
        <React.Fragment>
            <CssBaseline />
            <Container maxWidth="false" disableGutters={true} className={classes.container}>
                <Typography component="div" style={{ backgroundImage: `url(${props.image})`, backgroundPosition: 'center top', height: '40vh' }} />
                <Paper elevation={3} square={true} className={classes.paper}>
                    <Typography variant="h4" component="h1">
                        Paws on Parole
                    </Typography>
                    <Typography variant="subtitle1" component="h2">
                        Alachua County Animal Services
                        Prison-Based Dog Training Programs
                    </Typography>
                </Paper>
            </Container>
        </React.Fragment>
    )
}
