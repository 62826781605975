import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

export default function CenteredGrid() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={8} md={6} lg={2}>
                    <Paper className={classes.paper}> 1 xs=12 sm=8</Paper>
                </Grid>
                <Grid item xs={6} sm={4} >
                    <Paper className={classes.paper}> 2 xs=6</Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper className={classes.paper}> 3 xs=6</Paper>
                </Grid>
                <Grid item xs={3}>
                    <Paper className={classes.paper}> 4 xs=3</Paper>
                </Grid>
                <Grid item xs={3}>
                    <Paper className={classes.paper}> 5 xs=3</Paper>
                </Grid>
                <Grid item xs={3}>
                    <Paper className={classes.paper}> 6 xs=3</Paper>
                </Grid>
                <Grid item xs={3}>
                    <Paper className={classes.paper}>xs=3</Paper>
                </Grid>
            </Grid>
        </div>
    );
}