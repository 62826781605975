import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link'
import { currentAcademy } from './currentacademy'
import PhotoGallery from '../../components/PhotoGallery'
import AcademyTimeline from '../../components/AcademyTimeline'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import PetsIcon from '@material-ui/icons/Pets'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(4),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        paddingLeft: '15%',
        paddingRight: '15%',
        [theme.breakpoints.up('md')]: {
            paddingLeft: '20%',
            paddingRight: '20%',
        },
    },
    academyHeading: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        marginBottom: '20px',
        color: '#585858'
    },
    academyNumber: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    message: {
        //color: '#eaa900',
        //background: '#024977',
        color: '#024977',
        background: 'linear-gradient(180deg, rgba(255,255,255,0) 45%, #fff3d3 65%)',
        display:'inline',
    },
    currentList:{
        width:'45%',
        margin:'auto',
        [theme.breakpoints.down('sm')]: {
            width: '70%',
        },
    }
}));


export default function AcademyGallery(props) {
    const classes = useStyles();

    return (
        <Grid container spacing={0}>
            <Grid item xs={12}>
                <Paper square={true} elevation={0} className={classes.paper}>
                    <Typography variant="h4" component="h1" color="primary">
                        Meet our Paws On Parole Dogs
                    </Typography>
                </Paper>
            </Grid>
            <Grid item xs={12}>

                <Paper square={true} elevation={0} className={classes.paper}>
                    <Typography variant="h5" component="p" color="secondary" className={classes.message}>
                        More dogs will be up for adoption soon this summer 
                    </Typography>
                </Paper>
                <Paper square={true} elevation={0} className={classes.paper}>
                    <Typography variant="h6" component="h2" color="primary" className={classes.academyHeading}>
                        Current Academy
                </Typography>
                    {/* <Typography variant="h6" component="h3" color="primary" className={classes.academyNumber}>
                        {currentAcademy[0].academyNumbers}
                    </Typography> */}
                    <Typography variant="h6" component="p" color="primary">
                        {/* "{currentAcademy[0].academyName}" */}
                        "A Tail between 2 cities"
                    </Typography>

                    <List className={classes.currentList}>
                        <ListItem>
                            <ListItemAvatar>
                            <Avatar >
                                <PetsIcon color="primary"/>
                            </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Brooklyn" secondary="1 year old female, Bully" />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                            <Avatar>
                                <PetsIcon color="primary"/>
                            </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Houston" secondary="3 year old male, American Foxhound mix" />
                        </ListItem>
                        </List>
 
                    {/* <PhotoGallery photos={currentAcademy[0].photos} /> */}
                </Paper> 
                <Paper square={true} elevation={0} className={classes.paper}>
                    <Link href="/Adopt" color="inherit" title='Click here for adoption instructions'>
                        Click here for adoption instructions
                    </Link>
                </Paper>
            </Grid>
             <Grid item xs={12}>
                <Paper square={true} elevation={0} className={classes.paper}>
                    <Typography variant="h6" component="h2" color="primary" className={classes.academyHeading}>
                        Past Academies
                    </Typography>
                    <Typography variant="body1" paragraph>
                        (These dogs have all been adopted)
                    </Typography>
                    <AcademyTimeline /> 
                </Paper>
            </Grid> 
        </Grid >
    )
}