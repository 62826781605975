import React from "react";
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography'
import ContentSection from '../../components/ContentSection'
import Link from '@material-ui/core/Link';

import {adoptBlurb} from './adoptBlurb';

import { makeStyles } from '@material-ui/styles'

import happyEndingsGif from  '../../img/Happy-Endings.gif';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(4),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        paddingLeft: '15%',
        paddingRight: '15%',
        [theme.breakpoints.up('md')]: {
            paddingLeft: '20%',
            paddingRight: '20%',
        },
    },
    important: {
        color: 'red',
    },
}));

export default function AdoptPage() {

    const classes = useStyles();

    return (
        <Grid container spacing={0}>
            <Grid item xs={12}>
                <Paper elevation={0} className={classes.paper}>
                    <Typography variant="h4" component="h1" color="primary" >
                        Interested in Adopting?
                    </Typography>
                </Paper>
            </Grid>
            {
                Object.keys(adoptBlurb).map((item, index) => { //iterate through nested object 
                    const section = adoptBlurb[item];
                    return (
                        <ContentSection title={section.title} content={section.content} className={section.className} key={index} />
                    )  
                })
            }
            
            <Grid item xs={12}>
                <Paper square={true} elevation={0} className={classes.paper}>
                    <Typography variant="body1" component="p" color="primary" align="center" paragraph={true}>
                        {"If you have any questions please "}
                        <Link href={"/Contact"} color="inherit" underline="always" title={'Contact Page'}>
                            {'CONTACT US'}
                        </Link>
                    </Typography>
                    <Typography variant="body1" component="p" color="primary" align="center">
                        {"or call Hilary Hynes at 352-213-1241."}
                    </Typography>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper elevation={0} className={classes.paper}>
                    <Typography variant="body1" component="p" className={classes.important} align="center" paragraph={true}>
                        {"Click on the pup to see some of our"}
                    </Typography>
                    <Typography variant="body1" component="p" align="center" paragraph={true}>
                        <Link href={"/HappyEndings"} color="inherit" underline="always" title={'Happy Endings Page'}>
                            <img src={happyEndingsGif} alt={'Happy Endings gif'}/>
                        </Link>
                    </Typography>
                    <Typography variant="body1" component="p" className={classes.important} align="center" paragraph={true}>
                        {'"Happy Endings"'}
                    </Typography>
                </Paper>
            </Grid>
            
        </Grid>
    );
};