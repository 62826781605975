import React from "react";
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

import { makeStyles } from '@material-ui/styles'
import ContactForm from '../../components/ContactForm'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(4),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        paddingLeft: '15%',
        paddingRight: '15%',
        [theme.breakpoints.up('md')]: {
            paddingLeft: '20%',
            paddingRight: '20%',
        },
    },
}));

export default function ContactPage() {

    const classes = useStyles();

    return (
        <Grid container spacing={6}>
            <Grid item xs={12}>
                <Paper elevation={0} className={classes.paper}>
                    <Typography variant="h4" component="h1" color="primary" paragraph>
                        Contact Us
                    </Typography>
                    <Typography variant="body1" component="p">
                        Thanks for visiting our website...
                    </Typography>
                    <Typography variant="body1" component="p">
                        You can contact us by using the email form below or by
                        calling <strong> Hilary Hynes </strong>
                         at <Link href="tel:+1-352-264-6881" color="primary" title="Hilary Hynes' phone number #1">352-264-6881</Link>
                         or <Link href="tel:+1-352-213-1241" color="primary" title="Hilary Hynes' phone number #2">352-213-1241</Link>
                    </Typography>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper elevation={0} className={classes.paper}>
                    <ContactForm />
                </Paper>
            </Grid>
        </Grid>
    )
}