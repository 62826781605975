import React from 'react';
//import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

/*const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
}));*/

export default function FeaturedStory(props) {
    //const classes = useStyles();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <Typography variant="h6" component="h3">
                    {props.title}
                </Typography>
                <Typography variant="body1">
                    {props.caption}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Link href={props.link} target="_blank" color="inherit" title='Click to read the full story'>
                    <img src={props.image} style={{ width: '300px' }} alt={props.imageCaption}></img>
                    <Typography variant="caption" style={{ display: 'block' }}>
                        Click to read the full story
                    </Typography>
                </Link>
            </Grid>
        </Grid>
    )
}
