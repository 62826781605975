import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Drawer from '@material-ui/core/Drawer'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles';

import MenuIcon from '@material-ui/icons/Menu';
import FaceBookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';

import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({

    appBar: {
        position: 'relative',
        zIndex: '1400',
        flexGrow: '0',
        flexShrink: '1',
        flexBasis: 'auto',
        textAlign: 'left'
    },
    toolbar: theme.mixins.toolbar,
    drawer: {
        top: '56px !important',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    gridList: {
        display: 'grid',
        gridTemplateAreas: '"one two three four five six"',
        gridTemplateColumns: '2.5fr 1fr 1.25fr 1fr 1fr 1fr',
        msGridColumns: '2.5fr 1fr 1.25fr 1fr 1fr 1fr',
        paddingTop: '0px',
        paddingBottom: '0px',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    mobileList: {
        background: theme.palette.primary.light,
    },
    socialList: {
        display: 'flex',
        position: 'absolute',
        right: '6px',
        paddingTop: '0px',
        paddingBottom: '0px',
    },
    socialItem: {
        paddingLeft: '0',
        paddingRight: '8px',
    },
    navItem: {
        paddingTop: '0px',
        paddingBottom: '0px',
        width: 'auto',
        '&:nth-of-type(1)': {
            fontWeight: "bold",
            textTransform: 'uppercase',
            gridArea: 'one',
            msGridColumn: '1'
        },
        '&:nth-of-type(2)': {
            gridArea: 'two',
            msGridColumn: '2'
        },
        '&:nth-of-type(3)': {
            gridArea: 'three',
            msGridColumn: '3'
        },
        '&:nth-of-type(4)': {
            gridArea: 'four',
            msGridColumn: '4'
        },
        '&:nth-of-type(5)': {
            gridArea: 'five',
            msGridColumn: '5'
        },
        '&:nth-of-type(6)': {
            gridArea: 'six',
            msGridColumn: '6'
        },

    },
    mobileItem: {
        color: theme.palette.primary.main,
    },
    menuButton: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },

}));

const navItems = [
    { title: "Paws on Parole", link: "/" },
    { title: "About", link: "/About" },
    { title: "Our Dogs", link: "/OurDogs" },
    { title: "Adopt", link: "/Adopt" },
    { title: "Links", link: "/Links" },
    { title: "Contact", link: "/Contact" },

]

export default function Navigation() {

    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setOpen(!open);
    };
    return (
        <AppBar position="absolute" className={classes.appBar}>
            <Toolbar>
                <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
                    <MenuIcon />
                </IconButton>
                <Drawer
                    variant='temporary'
                    anchor='top'
                    classes={{
                        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                    }}
                    open={open}
                    className={classes.drawer}
                >
                    <div className={classes.toolbar} />
                    <List className={classes.mobileList}>
                        {
                            navItems.map((item, index) => (<ListItem className={classes.mobileItem} key={`nav1-${index}`}>
                                <Link href={item.link} color="inherit" title={item.title}>
                                    {item.title}
                                </Link>
                            </ListItem>))
                        }
                    </List>
                </Drawer>
                <List className={classes.gridList}>
                    {
                        navItems.map((item, index) => (<ListItem className={classes.navItem} key={`nav2-${index}`}>
                            <Link href={item.link} color="inherit" title={item.title}>
                                {item.title}
                            </Link>
                        </ListItem>))
                    }
                </List>
                <List className={classes.socialList}>
                    <ListItem className={classes.socialItem} key={`social-1`}>
                        <Link href="https://www.facebook.com/myacas.paws/" target="_blank" color="inherit" title="Paws on Parole Facebook Page">
                            <FaceBookIcon></FaceBookIcon>
                        </Link>
                    </ListItem>
                    <ListItem className={classes.socialItem} key={`social-2`}>
                        <Link href="https://www.instagram.com/paws_on_parole/" target="_blank" color="inherit" title="Paws on Parole Instagram Page">
                            <InstagramIcon></InstagramIcon>
                        </Link>
                    </ListItem>
                </List>
            </Toolbar>
        </AppBar >
    );
}