import React, { useState } from "react";
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';

import Button from '@material-ui/core/Button'
import FormGroup from '@material-ui/core/FormGroup'
import TextField from '@material-ui/core/TextField'
import Divider from '@material-ui/core/Divider'
import { useForm } from 'react-hook-form';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function FeedbackAlert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

//import Success from "./success"
//import ReCaptcha from "react-google-recaptcha"

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: '100%',
    },
    select: {
        minWidth: '100%',
    },
    selectEmpty: {
        //   marginTop: theme.spacing(2),
    },
    selectLabel: {
        padding: '0 4px',
        background: '#FFFFFF',
    },
    alignLeft: {
        textAlign: 'left',
    },
}));

export default function Form(props) {

    const {
        register,
        handleSubmit,
        errors,
        reset,
    } = useForm();

    const classes = useStyles();
    const [state, setState] = useState({
        //captchaToken: '',
        //isHuman: false,

        fullName: "",
        phoneNumber: "",
        email: "",
        address: "",
        city: "",
        state: "",
        zip: "",

        //dogName: "",
        ownRent: "",
        rentDogsAllowed: "",
        whyPOP: "",
        insideOutsideDay: "",
        insideOutsideNight: "",
        hoursAlone: "",
        fencedYard: '',
        fenceKind: '',
        secureDog: '',
        rabies: '',
        intention: '',
        allergic: '',
        listPets: "",
        spayed: "",
        veterinary: '',
        primaryCaregiver: "",
        children: "",
        minorsAges: "",
        food: '',
        behaviour: '',
        deal: '',
        citation: '',
        /*
        integrate: "",
        physicalLimitation: "",
        describePhysicalLimitation: "",
        restrictions: '',
        move: "",
        excercise: '',
        hoursOutside: "",
        vacation: '',    
        collar: '',
        training: '',
        trainingSelf: '',
        secondaryCaregiver: '',
        veterinaryPay: '',
        vetDetails: '',
        treatment: '',
        contactPOP: '',
        hear: '',
        other: '',
        */
        attestation: '',
        successfulSubmit: false
    });

    const handleChange = (event) => {
        const name = event.target.name;
        setState({
            ...state,
            [name]: event.target.value,
        });
    };

    const maskPhoneNumber = (e) => {
        let x = e.target.value
            .replace(/\D/g, '')
            .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        e.target.value = !x[2]
            ? x[1]
            : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');

        handleChange(e);
    };

    const maskNonDigits = (e) => {

        let x = e.target.value
            .replace(/\D/g, '')

        e.target.value = x;
        handleChange(e);

    };

    const [submitStatus, setSubmitStatus] = useState(null);
    const [feedbackOpen, setFeedbackOpen] = useState(false);

    const handleFeedbackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setFeedbackOpen(false);
    };

    const onAdoptionFormSubmit = (data) => {
        console.log(data)

        const body = data;

        fetch(
            ' https://api.alachuacounty.us/paws-on-parole-api/adoptionApplication/',
            {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify(body)
            }
        )
            .then((response) => {
                setSubmitStatus(response.status);
                reset();
                /*reset({
                    fullName: "",
                    phoneNumber: "",
                    email: "",
                    address: "",
                    city: "",
                    state: "",
                    zip: "",
                    ownRent: "",
                    rentDogsAllowed: "",
                    whyPOP: "",
                    insideOutsideDay: "",
                    insideOutsideNight: "",
                    hoursAlone: "",
                    fencedYard: '',
                    fenceKind: '',
                    secureDog: '',
                    rabies: '',
                    intention: '',
                    allergic: '',
                    listPets: "",
                    spayed: "",
                    veterinary: '',
                    primaryCaregiver: "",
                    children: "",
                    minorsAges: "",
                    food: '',
                    behaviour: '',
                    deal: '',
                    citation: '',        
                    attestation: '',
                    successfulSubmit: false
                });*/
            })
            .catch((err) => {
                console.log(err);
                setSubmitStatus(500);
            }); 

        setFeedbackOpen(true);

    }
    return (
        < form onSubmit={handleSubmit(onAdoptionFormSubmit)} >
            <FormGroup>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            name='fullName'
                            label='Name*'
                            id='fullName'
                            placeholder='Name'
                            variant='outlined'
                            size='small'
                            fullWidth={true}
                            onChange={handleChange}
                            autoComplete='on'
                            inputProps={{
                                "aria-required": 'true',
                            }}
                            inputRef={register({
                                required: true
                            })}>
                        </TextField>

                        {errors.fullName &&
                            errors.fullName.type ===
                            'required' && (
                                <Alert severity='error' id='fullNameError'>Required</Alert>
                            )}

                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            name='phoneNumber'
                            label='Phone Number*'
                            id='phoneNumber'
                            placeholder='(555) 555-5555'
                            variant='outlined'
                            size='small'
                            fullWidth={true}
                            onChange={maskPhoneNumber}
                            // autoComplete='new-phoneNumber'
                            autoComplete='on'
                            inputProps={{
                                "aria-required": 'true',
                            }}
                            inputRef={register({
                                required: true,
                                minLength: 14,
                            })}>
                        </TextField>
                        {errors.phoneNumber &&
                            errors.phoneNumber.type === 'required' && (
                                <Alert severity='error' id="phoneNumberError">Required</Alert>
                            )}
                        {errors.phoneNumber &&
                            errors.phoneNumber.type === 'minLength' && (
                                <Alert severity='error' id="phoneNumberValidationError">Please enter a valid 10 digit phone number</Alert>
                            )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            name='email'
                            label='Email Address*'
                            id='email'
                            placeholder='Email Address'
                            variant='outlined'
                            size='small'
                            fullWidth={true}
                            onChange={handleChange}
                            autoComplete='on'
                            inputProps={{
                                "aria-required": 'true',
                            }}
                            inputRef={register({
                                required: true,
                                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            })}>
                        </TextField>
                        {errors.email &&
                            errors.email.type === 'required' && (
                                <Alert severity='error' id='emailError'>Required</Alert>
                            )}
                        {errors.email &&
                            errors.email.type === 'pattern' && (
                                <Alert severity='error' id='emailValidationError'>Invalid Email </Alert>
                            )}
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            name='address'
                            label='Address*'
                            id='address'
                            placeholder='Address'
                            variant='outlined'
                            size='small'
                            fullWidth={true}
                            onChange={handleChange}
                            inputProps={{
                                "aria-required": 'true',
                            }}
                            inputRef={register({
                                required: true
                            })}>
                        </TextField>
                        {errors.address &&
                            errors.address.type ===
                            'required' && (
                                <Alert severity='error' id='addressError'>Required</Alert>
                            )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            name='city'
                            label='City*'
                            id='city'
                            placeholder='City'
                            variant='outlined'
                            size='small'
                            fullWidth={true}
                            onChange={handleChange}
                            inputProps={{
                                "aria-required": 'true',
                            }}
                            inputRef={register({
                                required: true
                            })}>
                        </TextField>
                        {errors.city &&
                            errors.city.type ===
                            'required' && (
                                <Alert severity='error' id='cityError'>Required</Alert>
                            )}
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                        <TextField
                            name='state'
                            label='State*'
                            id='state'
                            placeholder='state'
                            variant='outlined'
                            size='small'
                            fullWidth={true}
                            onChange={handleChange}
                            inputProps={{
                                "aria-required": 'true',
                            }}
                            inputRef={register({
                                required: true
                            })}>
                        </TextField>
                        {errors.state &&
                            errors.state.type ===
                            'required' && (
                                <Alert severity='error' id='stateError'>Required</Alert>
                            )}
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                        <TextField
                            name='zip'
                            label='Zip Code*'
                            id='zip'
                            placeholder='Zip Code'
                            variant='outlined'
                            size='small'
                            fullWidth={true}
                            onChange={maskNonDigits}
                            inputProps={{
                                "aria-required": 'true',
                            }}
                            inputRef={register({
                                required: true
                            })}>
                        </TextField>
                        {errors.zip &&
                            errors.zip.type ===
                            'required' && (
                                <Alert severity='error' id='zipError'>Required</Alert>
                            )}
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    {/*                     <Grid item xs={12} >
                        <TextField
                            name='dogName'
                            label='Name of dog you are applying for*'
                            id='dogName'
                            placeholder='Name of dog you are applying for'
                            variant='outlined'
                            size='small'
                            fullWidth={true}
                            onChange={handleChange}
                            inputProps={{
                                "aria-required": 'true',
                            }}
                            inputRef={register({
                                required: true
                            })}>
                        </TextField>
                        {errors.dogName &&
                            errors.dogName.type ===
                            'required' && (
                                <Alert severity='error' id='dogNameError'>Required</Alert>
                            )}
                    </Grid> */}
                    <Grid item xs={12} className={classes.alignLeft} >
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Do you own your home or rent or live with parents ?*</FormLabel>
                            <RadioGroup aria-label="Do you own your home or rent ?"
                                name="ownRent"
                                onChange={handleChange}
                                row={true}
                            >
                                <FormControlLabel value="Own" control={
                                    <Radio color="primary"
                                        inputProps={{
                                            "aria-required": 'true',
                                        }}
                                        inputRef={register({
                                            required: true
                                        })} />} label="Own" />
                                <FormControlLabel value="Rent" control={
                                    <Radio color="primary"
                                        inputProps={{
                                            "aria-required": 'true',
                                        }}
                                        inputRef={register({
                                            required: true
                                        })} />} label="Rent" />
                                <FormControlLabel value="LiveWithParents" control={
                                    <Radio color="primary"
                                        inputProps={{
                                            "aria-required": 'true',
                                        }}
                                        inputRef={register({
                                            required: true
                                        })} />} label="Live with Parents" />
                            </RadioGroup>
                        </FormControl>

                        {errors.ownRent &&
                            errors.ownRent.type ===
                            'required' && (
                                <Alert severity='error' id='ownRentError'>Required</Alert>
                            )}
                    </Grid>
                    {
                        state.ownRent !== "" && (
                            <>
                                <Grid item xs={12} className={classes.alignLeft} >
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">As a renter, most leases require some type of pet deposit or signed agreement before the pet can move into the residence. If your landlord has size or weight restrictions on pets, we strongly recommend that you follow these guidelines.
                                        <br /> Also, if you live in a neighborhood with a Home Owners Association restrictions on type and size of pet may apply.*</FormLabel>

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onChange={handleChange}
                                                    name="rentDogsAllowed"
                                                    color="primary"
                                                    inputRef={register({
                                                        required: true
                                                    })}
                                                />
                                            }
                                            label="I understand"
                                        />
                                    </FormControl>

                                    {errors.rentDogsAllowed &&
                                        errors.rentDogsAllowed.type ===
                                        'required' && (
                                            <Alert severity='error' id='rentDogsAllowedError'>Required</Alert>
                                        )}
                                </Grid>
                            </>
                        )
                    }

                    <Grid item xs={12}>
                        <TextField
                            name="whyPOP"
                            label="Why are you looking to adopt a pet?*"
                            id='whyPOP'
                            multiline
                            rows={4}
                            defaultValue=""
                            placeholder='Why are you looking to adopt a pet?'
                            variant="outlined"
                            fullWidth={true}
                            onChange={handleChange}
                            inputProps={{
                                "aria-required": 'true',
                            }}
                            inputRef={register({
                                required: true
                            })}
                        />
                        {errors.whyPOP &&
                            errors.whyPOP.type ===
                            'required' && (
                                <Alert severity='error' id='whyPOPError'>Required</Alert>
                            )}
                    </Grid>
                    <Grid item xs={12} className={classes.alignLeft} >
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Where do you plan to keep your pet during the day?*</FormLabel>
                            <RadioGroup aria-label="Where do you plan to keep your pet during the day?"
                                name="insideOutsideDay"
                                onChange={handleChange}
                                row={true}
                            >
                                <FormControlLabel value="Inside" control={
                                    <Radio color="primary"
                                        inputProps={{
                                            "aria-required": 'true',
                                        }}
                                        inputRef={register({
                                            required: true
                                        })} />} label="Inside" />
                                <FormControlLabel value="Outside" control={
                                    <Radio color="primary"
                                        inputProps={{
                                            "aria-required": 'true',
                                        }}
                                        inputRef={register({
                                            required: true
                                        })} />} label="Outside" />
                            </RadioGroup>
                        </FormControl>

                        {errors.insideOutsideDay &&
                            errors.insideOutsideDay.type ===
                            'required' && (
                                <Alert severity='error' id='insideOutsideDayError'>Required</Alert>
                            )}
                    </Grid>
                    <Grid item xs={12} className={classes.alignLeft} >
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Where do you plan to keep your pet during the night?*</FormLabel>
                            <RadioGroup aria-label="Where do you plan to keep your pet during the night?"
                                name="insideOutsideNight"
                                onChange={handleChange}
                                row={true}
                            >
                                <FormControlLabel value="Inside" control={
                                    <Radio color="primary"
                                        inputProps={{
                                            "aria-required": 'true',
                                        }}
                                        inputRef={register({
                                            required: true
                                        })} />} label="Inside" />
                                <FormControlLabel value="Outside" control={
                                    <Radio color="primary"
                                        inputProps={{
                                            "aria-required": 'true',
                                        }}
                                        inputRef={register({
                                            required: true
                                        })} />} label="Outside" />
                            </RadioGroup>
                        </FormControl>

                        {errors.insideOutsideNight &&
                            errors.insideOutsideNight.type ===
                            'required' && (
                                <Alert severity='error' id='insideOutsideNightError'>Required</Alert>
                            )}
                    </Grid>

                    <Grid item xs={12} >
                        <TextField
                            name='hoursAlone'
                            label='How many hours during the day will your pet be left alone?*'
                            id='hoursAlone'
                            placeholder='How many hours during the day will your pet be left alone?'
                            variant='outlined'
                            size='small'
                            fullWidth={true}
                            onChange={handleChange}
                            inputProps={{
                                "aria-required": 'true',
                            }}
                            inputRef={register({
                                required: true
                            })}>
                        </TextField>
                        {errors.hoursAlone &&
                            errors.hoursAlone.type ===
                            'required' && (
                                <Alert severity='error' id='hoursAloneError'>Required</Alert>
                            )}
                    </Grid>

                    <Grid item xs={12} className={classes.alignLeft} >
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Do you have a fenced yard?*</FormLabel>
                            <RadioGroup aria-label="Do you have a fenced yard?"
                                name="fencedYard"
                                onChange={handleChange}
                                row={true}
                            >
                                <FormControlLabel value="Yes" control={
                                    <Radio color="primary"
                                        inputProps={{
                                            "aria-required": 'true',
                                        }}
                                        inputRef={register({
                                            required: true
                                        })} />} label="Yes" />
                                <FormControlLabel value="No" control={
                                    <Radio color="primary"
                                        inputProps={{
                                            "aria-required": 'true',
                                        }}
                                        inputRef={register({
                                            required: true
                                        })} />} label="No" />
                            </RadioGroup>
                        </FormControl>
                        {errors.fencedYard &&
                            errors.fencedYard.type ===
                            'required' && (
                                <Alert severity='error' id='fencedYardError'>Required</Alert>
                            )}
                    </Grid>

                    {state.fencedYard === "Yes" && (
                        <Grid item xs={12} >
                            <TextField
                                name='fenceKind'
                                label='If you said yes, what kind of fence do you have?'
                                id='fenceKind'
                                placeholder='If you said yes, what kind of fence do you have?'
                                variant='outlined'
                                size='small'
                                fullWidth={true}
                                onChange={handleChange}
                                inputRef={register({})}>
                            </TextField>
                        </Grid>

                    )}

                    {state.fencedYard === "No" && (
                        <Grid item xs={12} >
                            <TextField
                                name='secureDog'
                                label="If you don't have a fence how will you secure your dog outside?"
                                id='secureDog'
                                placeholder="If you don't have a fence how will you secure your dog outside?"
                                variant='outlined'
                                size='small'
                                fullWidth={true}
                                onChange={handleChange}
                                inputRef={register({})}>
                            </TextField>
                        </Grid>

                    )}


                    <Grid item xs={12} className={classes.alignLeft} >
                        <FormControl component="fieldset">
                            <FormLabel component="legend">I have read and understand "Alachua County Animal Services Adoption Procedures" and will provide proof of rabies vaccination for my current pets and a drivers license or Florida ID *</FormLabel>
                            <RadioGroup aria-label="I have read and understand 'Alachua County Animal Services Adoption Procedures' and will provide proof of rabies vaccination for my current pets and a drivers license or Florida ID"
                                name="rabies"
                                onChange={handleChange}
                                row={true}
                            >
                                <FormControlLabel value="Yes" control={
                                    <Radio color="primary"
                                        inputProps={{
                                            "aria-required": 'true',
                                        }}
                                        inputRef={register({
                                            required: true
                                        })} />} label="Yes" />
                                <FormControlLabel value="No" control={
                                    <Radio color="primary"
                                        inputProps={{
                                            "aria-required": 'true',
                                        }}
                                        inputRef={register({
                                            required: true
                                        })} />} label="No" />
                            </RadioGroup>
                        </FormControl>
                        {errors.rabies &&
                            errors.rabies.type ===
                            'required' && (
                                <Alert severity='error' id='rabies'>Required</Alert>
                            )}
                    </Grid>

                    <Grid item xs={12} className={classes.alignLeft} >
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Is everyone in your household aware of your intention to adopt a pet?</FormLabel>
                            <RadioGroup aria-label="Is everyone in your household aware of your intention to adopt a pet?"
                                name="intention"
                                onChange={handleChange}
                                row={true}
                            >
                                <FormControlLabel value="Yes" control={
                                    <Radio color="primary"
                                        inputProps={{
                                            "aria-required": 'true',
                                        }}
                                        inputRef={register({
                                            required: true
                                        })} />} label="Yes" />
                                <FormControlLabel value="No" control={
                                    <Radio color="primary"
                                        inputProps={{
                                            "aria-required": 'true',
                                        }}
                                        inputRef={register({
                                            required: true
                                        })} />} label="No" />
                            </RadioGroup>
                        </FormControl>
                        {errors.intention &&
                            errors.intention.type ===
                            'required' && (
                                <Alert severity='error' id='intention'>Required</Alert>
                            )}
                    </Grid>

                    <Grid item xs={12} className={classes.alignLeft} >
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Is anyone in your household allergic to pets?</FormLabel>
                            <RadioGroup aria-label="Is anyone in your household allergic to pets?"
                                name="allergic"
                                onChange={handleChange}
                                row={true}
                            >
                                <FormControlLabel value="Yes" control={
                                    <Radio color="primary"
                                        inputProps={{
                                            "aria-required": 'true',
                                        }}
                                        inputRef={register({
                                            required: true
                                        })} />} label="Yes" />
                                <FormControlLabel value="No" control={
                                    <Radio color="primary"
                                        inputProps={{
                                            "aria-required": 'true',
                                        }}
                                        inputRef={register({
                                            required: true
                                        })} />} label="No" />
                            </RadioGroup>
                        </FormControl>
                        {errors.allergic &&
                            errors.allergic.type ===
                            'required' && (
                                <Alert severity='error' id='allergic'>Required</Alert>
                            )}
                    </Grid>

                    <Grid item xs={12}>
                        <FormLabel component="legend">Please list all pets you own/have owned in the past five years. Please include name/type of animal, age, sex (spayed/ neutered?), Current on shots? Veterinarian used? and status (home, deceased etc)</FormLabel>
                        <br />
                        <TextField
                            name="listPets"
                            label="List all pets you've had in the past 5 years."
                            id='listPets'
                            multiline
                            rows={4}
                            defaultValue=""
                            placeholder="List all pets you've had in the past 5 years"
                            variant="outlined"
                            fullWidth={true}
                            onChange={handleChange}
                            inputProps={{
                                "aria-required": 'true',
                            }}
                            inputRef={register({})}
                        />
                    </Grid>

                    <Grid item xs={12} className={classes.alignLeft} >
                        <FormControl component="fieldset">
                            <FormLabel component="legend">All animals adopted from Alachua County Animal Service must be spayed or neutered prior to going home. We also recommend all already owned animals be spayed and neutered. If you are interested in having any of your pet(s) spayed or neutered please discuss this with your adoption counselor.</FormLabel>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={handleChange}
                                        name="spayed"
                                        color="primary"
                                        inputRef={register({
                                            required: true
                                        })}
                                    />
                                }
                                label="I understand"
                            />
                        </FormControl>

                        {errors.spayed &&
                            errors.spayed.type ===
                            'required' && (
                                <Alert severity='error' id='rentDogsAllowedError'>Required</Alert>
                            )}

                    </Grid>

                    <Grid item xs={12} className={classes.alignLeft} >
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Although Animal Services has provided a medical exam and some veterinary services for your new pet, the animal will need additional care. Are you prepared to provide the new pet with all necessary vet care?</FormLabel>
                            <RadioGroup aria-label="Although Animal Services has provided a medical exam and some veterinary services for your new pet, the animal will need additional care. Are you prepared to provide the new pet with all necessary vet care?"
                                name="veterinary"
                                onChange={handleChange}
                                row={true}
                            >
                                <FormControlLabel value="Yes" control={
                                    <Radio color="primary"
                                        inputProps={{
                                            "aria-required": 'true',
                                        }}
                                        inputRef={register({
                                            required: true
                                        })} />} label="Yes" />
                                <FormControlLabel value="No" control={
                                    <Radio color="primary"
                                        inputProps={{
                                            "aria-required": 'true',
                                        }}
                                        inputRef={register({
                                            required: true
                                        })} />} label="No" />
                            </RadioGroup>
                        </FormControl>
                        {errors.veterinary &&
                            errors.veterinary.type ===
                            'required' && (
                                <Alert severity='error' id='veterinaryError'>Required</Alert>
                            )}
                    </Grid>

                    <Grid item xs={12} >
                        <TextField
                            name='primaryCaregiver'
                            label='Who will be the primary caregiver for this pet?*'
                            id='primaryCaregiver'
                            placeholder='Who will be the primary caregiver for this pet?'
                            variant='outlined'
                            size='small'
                            fullWidth={true}
                            onChange={handleChange}
                            inputProps={{
                                "aria-required": 'true',
                            }}
                            inputRef={register({
                                required: true
                            })}>
                        </TextField>
                        {errors.primaryCaregiver &&
                            errors.primaryCaregiver.type ===
                            'required' && (
                                <Alert severity='error' id='primaryCaregiverError'>Required</Alert>
                            )}
                    </Grid>


                    <Grid item xs={12} className={classes.alignLeft} >
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Do you have children in the household?</FormLabel>
                            <RadioGroup aria-label="Do you have children in the household?"
                                name="children"
                                onChange={handleChange}
                                row={true}
                            >
                                <FormControlLabel value="Yes" control={
                                    <Radio color="primary"
                                        inputProps={{
                                            "aria-required": 'true',
                                        }}
                                        inputRef={register({
                                            required: true
                                        })} />} label="Yes" />
                                <FormControlLabel value="No" control={
                                    <Radio color="primary"
                                        inputProps={{
                                            "aria-required": 'true',
                                        }}
                                        inputRef={register({
                                            required: true
                                        })} />} label="No" />
                            </RadioGroup>
                        </FormControl>
                        {errors.children &&
                            errors.children.type ===
                            'required' && (
                                <Alert severity='error' id='childrenError'>Required</Alert>
                            )}
                    </Grid>

                    {
                        state.children === "Yes" && (

                            <Grid item xs={12} >
                                <TextField
                                    name='minorsAges'
                                    label='Ages of children in your home*'
                                    id='minorsAges'
                                    placeholder='Ages of children in your home'
                                    variant='outlined'
                                    size='small'
                                    fullWidth={true}
                                    onChange={handleChange}
                                    inputProps={{
                                        "aria-required": 'true',
                                    }}
                                    inputRef={register({
                                        required: true
                                    })}>
                                </TextField>
                                {errors.minorsAges &&
                                    errors.minorsAges.type ===
                                    'required' && (
                                        <Alert severity='error' id='minorsAgesError'>Required</Alert>
                                    )}
                            </Grid>

                        )
                    }

                    <Grid item xs={12} >
                        <TextField
                            name='food'
                            label='What brand of food will you feed your dog?*'
                            id='food'
                            placeholder='What brand of food will you feed your dog?'
                            variant='outlined'
                            size='small'
                            fullWidth={true}
                            onChange={handleChange}
                            inputProps={{
                                "aria-required": 'true',
                            }}
                            inputRef={register({
                                required: true
                            })}>
                        </TextField>
                        {errors.food &&
                            errors.food.type ===
                            'required' && (
                                <Alert severity='error' id='foodError'>Required</Alert>
                            )}
                    </Grid>

                    <Grid item xs={12} >
                        <TextField
                            name='behaviour'
                            label='What type of behaviors would you NOT tolerate?*'
                            id='behaviour'
                            placeholder='What type of behaviors would you NOT tolerate?'
                            variant='outlined'
                            size='small'
                            fullWidth={true}
                            onChange={handleChange}
                            inputProps={{
                                "aria-required": 'true',
                            }}
                            inputRef={register({
                                required: true
                            })}>
                        </TextField>
                        {errors.behaviour &&
                            errors.behaviour.type ===
                            'required' && (
                                <Alert severity='error' id='behaviourError'>Required</Alert>
                            )}
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            name="deal"
                            label="How will you deal with undesirable behaviors?*"
                            id='deal'
                            multiline
                            rows={4}
                            defaultValue=""
                            placeholder='How will you deal with undesirable behaviors?'
                            variant="outlined"
                            fullWidth={true}
                            onChange={handleChange}
                            inputProps={{
                                "aria-required": 'true',
                            }}
                            inputRef={register({
                                required: true
                            })}
                        />
                        {errors.deal &&
                            errors.deal.type ===
                            'required' && (
                                <Alert severity='error' id='dealError'>Required</Alert>
                            )}
                    </Grid>

                    <Grid item xs={12} className={classes.alignLeft} >
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Have you ever received a citation from Animal Services, been convicted of cruelty to animals or had an animal impounded by our facility?</FormLabel>
                            <RadioGroup aria-label="Have you ever received a citation from Animal Services, been convicted of cruelty to animals or had an animal impounded by our facility?"
                                name="citation"
                                onChange={handleChange}
                                row={true}
                            >
                                <FormControlLabel value="Yes" control={
                                    <Radio color="primary"
                                        inputProps={{
                                            "aria-required": 'true',
                                        }}
                                        inputRef={register({
                                            required: true
                                        })} />} label="Yes" />
                                <FormControlLabel value="No" control={
                                    <Radio color="primary"
                                        inputProps={{
                                            "aria-required": 'true',
                                        }}
                                        inputRef={register({
                                            required: true
                                        })} />} label="No" />
                            </RadioGroup>
                        </FormControl>
                        {errors.citation &&
                            errors.citation.type ===
                            'required' && (
                                <Alert severity='error' id='citation'>Required</Alert>
                            )}
                    </Grid>

                    <Grid item xs={12} className={classes.alignLeft} >
                        <FormControl component="fieldset">

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={handleChange}
                                        name="attestation"
                                        color="primary"
                                        inputRef={register({
                                            required: true
                                        })}
                                    />
                                }
                                label="I certify that the information listed above is true and correct to the best of my knowledge."
                            />


                        </FormControl>
                        {errors.attestation &&
                            errors.attestation.type ===
                            'required' && (
                                <Alert severity='error' id='attestation'>Required</Alert>
                            )}
                    </Grid>


                    {/*


                    <Grid item xs={12}>
                        <TextField
                            name="integrate"
                            label="How do you plan to integrate this dog into your life?*"
                            id='integrate'
                            multiline
                            rows={4}
                            defaultValue=""
                            placeholder='How do you plan to integrate this dog into your life?'
                            variant="outlined"
                            fullWidth={true}
                            onChange={handleChange}
                            inputProps={{
                                "aria-required": 'true',
                            }}
                            inputRef={register({
                                required: true
                            })}
                        />
                        {errors.integrate &&
                            errors.integrate.type ===
                            'required' && (
                                <Alert severity='error' id='integrateError'>Required</Alert>
                            )}
                    </Grid>

                    <Grid item xs={12} className={classes.alignLeft} >
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Does the primary caregiver have any physical limitations that may interfere with the dog's care?*</FormLabel>
                            <RadioGroup aria-label="Does the primary caregiver have any physical limitations that may interfere with the dog's care?"
                                name="physicalLimitation"
                                onChange={handleChange}
                                row={true}
                            >
                                <FormControlLabel value="Yes" control={
                                    <Radio color="primary"
                                        inputProps={{
                                            "aria-required": 'true',
                                        }}
                                        inputRef={register({
                                            required: true
                                        })} />} label="Yes" />
                                <FormControlLabel value="No" control={
                                    <Radio color="primary"
                                        inputProps={{
                                            "aria-required": 'true',
                                        }}
                                        inputRef={register({
                                            required: true
                                        })} />} label="No" />
                            </RadioGroup>
                        </FormControl>

                        {errors.physicalLimitation &&
                            errors.physicalLimitation.type ===
                            'required' && (
                                <Alert severity='error' id='physicalLimitationError'>Required</Alert>
                            )}
                    </Grid>
                    {
                        state.physicalLimitation === "Yes" && (
                            <Grid item xs={12}>
                                <TextField
                                    name="describePhysicalLimitation"
                                    label="If you answered yes, please explain"
                                    id='describePhysicalLimitation'
                                    multiline
                                    rows={4}
                                    defaultValue=""
                                    placeholder='Please explain'
                                    variant="outlined"
                                    fullWidth={true}
                                    onChange={handleChange}
                                    inputProps={{
                                        "aria-required": 'true',
                                    }}
                                    inputRef={register({
                                        required: true
                                    })}
                                />
                                {errors.describePhysicalLimitation &&
                                    errors.describePhysicalLimitation.type ===
                                    'required' && (
                                        <Alert severity='error' id='describePhysicalLimitationError'>Required</Alert>
                                    )}
                            </Grid>
                        )

                    }



                    <Grid item xs={12} >
                        <TextField
                            name='restrictions'
                            label='Are there any restrictions?'
                            id='minorsAges'
                            placeholder='Are there any restrictions?'
                            variant='outlined'
                            size='small'
                            fullWidth={true}
                            onChange={handleChange}
                            inputProps={{
                                "aria-required": 'true',
                            }}
                        >
                        </TextField>
                    </Grid>





                    <Grid item xs={12}>
                        <TextField
                            name="move"
                            label="If you move where dogs are not allowed, what will you do with your dog?*"
                            id='move'
                            multiline
                            rows={4}
                            defaultValue=""
                            placeholder='If you move where dogs are not allowed, what will you do with your dog?'
                            variant="outlined"
                            fullWidth={true}
                            onChange={handleChange}
                            inputProps={{
                                "aria-required": 'true',
                            }}
                            inputRef={register({
                                required: true
                            })}
                        />
                        {errors.move &&
                            errors.move.type ===
                            'required' && (
                                <Alert severity='error' id='moveError'>Required</Alert>
                            )}
                    </Grid>





                    <Grid item xs={12} >
                        <TextField
                            name='hoursOutside'
                            label='How many hours a day will your dog be outside?*'
                            id='hoursOutside'
                            placeholder='How many hours a day will your dog be outside?'
                            variant='outlined'
                            size='small'
                            fullWidth={true}
                            onChange={handleChange}
                            inputProps={{
                                "aria-required": 'true',
                            }}
                            inputRef={register({
                                required: true
                            })}>
                        </TextField>
                        {errors.hoursOutside &&
                            errors.hoursOutside.type ===
                            'required' && (
                                <Alert severity='error' id='hoursAloneError'>Required</Alert>
                            )}
                    </Grid>



                    <Grid item xs={12}>
                        <TextField
                            name="vacation"
                            label="What provisions will you make for your dog when you go on vacation?*"
                            id='move'
                            multiline
                            rows={4}
                            defaultValue=""
                            placeholder='What provisions will you make for your dog when you go on vacation?'
                            variant="outlined"
                            fullWidth={true}
                            onChange={handleChange}
                            inputProps={{
                                "aria-required": 'true',
                            }}
                            inputRef={register({
                                required: true
                            })}
                        />
                        {errors.vacation &&
                            errors.vacation.type ===
                            'required' && (
                                <Alert severity='error' id='vacationError'>Required</Alert>
                            )}
                    </Grid>


                    <Grid item xs={12} >
                        <TextField
                            name='exercise'
                            label='How will you exercise your dog?*'
                            id='exercise'
                            placeholder='How will you exercise your dog?'
                            variant='outlined'
                            size='small'
                            fullWidth={true}
                            onChange={handleChange}
                            inputProps={{
                                "aria-required": 'true',
                            }}
                            inputRef={register({
                                required: true
                            })}>
                        </TextField>
                        {errors.exercise &&
                            errors.exercise.type ===
                            'required' && (
                                <Alert severity='error' id='exerciseError'>Required</Alert>
                            )}
                    </Grid>
                    

                    <Grid item xs={12} className={classes.alignLeft} >
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Will you be using an electronic (shock) collar to train your dog?*</FormLabel>
                            <RadioGroup aria-label="Will you be using an electronic (shock) collar to train your dog?"
                                name="collar"
                                onChange={handleChange}
                                row={true}
                            >
                                <FormControlLabel value="Yes" control={
                                    <Radio color="primary"
                                        inputProps={{
                                            "aria-required": 'true',
                                        }}
                                        inputRef={register({
                                            required: true
                                        })} />} label="Yes" />
                                <FormControlLabel value="No" control={
                                    <Radio color="primary"
                                        inputProps={{
                                            "aria-required": 'true',
                                        }}
                                        inputRef={register({
                                            required: true
                                        })} />} label="No" />
                            </RadioGroup>
                        </FormControl>
                        {errors.collar &&
                            errors.collar.type ===
                            'required' && (
                                <Alert severity='error' id='collarError'>Required</Alert>
                            )}
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            name="training"
                            label="Paws on Parole dogs are taught the basics, how do you plan to continue your dogs training?*"
                            id='training'
                            multiline
                            rows={4}
                            defaultValue=""
                            placeholder='Paws on Parole dogs are taught the basics, how do you plan to continue your dogs training?'
                            variant="outlined"
                            fullWidth={true}
                            onChange={handleChange}
                            inputProps={{
                                "aria-required": 'true',
                            }}
                            inputRef={register({
                                required: true
                            })}
                        />
                        {errors.training &&
                            errors.training.type ===
                            'required' && (
                                <Alert severity='error' id='trainingError'>Required</Alert>
                            )}
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            name="trainingSelf"
                            label="Do you plan to take training classes with your dog?*"
                            id='trainingSelf'
                            defaultValue=""
                            placeholder='Do you plan to take training classes with your dog?'
                            variant="outlined"
                            fullWidth={true}
                            onChange={handleChange}
                            inputProps={{
                                "aria-required": 'true',
                            }}
                            inputRef={register({
                                required: true
                            })}
                        />
                        {errors.trainingSelf &&
                            errors.trainingSelf.type ===
                            'required' && (
                                <Alert severity='error' id='trainingSelfError'>Required</Alert>
                            )}
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            name="secondaryCaregiver"
                            label="Dogs can live 15 or more years. Who will care for your dog if you are no longer able?*"
                            id='secondaryCaregiver'
                            defaultValue=""
                            placeholder='Who will care for your dog if you are no longer able?'
                            variant="outlined"
                            fullWidth={true}
                            onChange={handleChange}
                            inputProps={{
                                "aria-required": 'true',
                            }}
                            inputRef={register({
                                required: true
                            })}
                        />
                        {errors.secondaryCaregiver &&
                            errors.secondaryCaregiver.type ===
                            'required' && (
                                <Alert severity='error' id='secondaryCaregiverError'>Required</Alert>
                            )}
                    </Grid>

                    <Grid item xs={12} className={classes.alignLeft} >
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Are you willing and able to pay for all inoculations and veterinary exams and care?*</FormLabel>
                            <RadioGroup aria-label="Are you willing and able to pay for all inoculations and veterinary exams and care?"
                                name="veterinaryPay"
                                onChange={handleChange}
                                row={true}
                            >
                                <FormControlLabel value="Yes" control={
                                    <Radio color="primary"
                                        inputProps={{
                                            "aria-required": 'true',
                                        }}
                                        inputRef={register({
                                            required: true
                                        })} />} label="Yes" />
                                <FormControlLabel value="No" control={
                                    <Radio color="primary"
                                        inputProps={{
                                            "aria-required": 'true',
                                        }}
                                        inputRef={register({
                                            required: true
                                        })} />} label="No" />
                            </RadioGroup>
                        </FormControl>
                        {errors.veterinaryPay &&
                            errors.veterinaryPay.type ===
                            'required' && (
                                <Alert severity='error' id='veterinaryPayError'>Required</Alert>
                            )}
                    </Grid>

                    {
                        state.veterinary === "Yes" && (

                            <Grid item xs={12}>
                                <TextField
                                    name="vetDetails"
                                    label="If you answered yes, please provide name and location of vet/hospital*"
                                    id='vetDetails'
                                    multiline
                                    rows={4}
                                    defaultValue=""
                                    placeholder='Please provide name and location of vet/hospital'
                                    variant="outlined"
                                    fullWidth={true}
                                    onChange={handleChange}
                                    inputProps={{
                                        "aria-required": 'true',
                                    }}
                                    inputRef={register({
                                        required: true
                                    })}
                                />
                                {errors.vetDetails &&
                                    errors.vetDetails.type ===
                                    'required' && (
                                        <Alert severity='error' id='vetDetailsError'>Required</Alert>
                                    )}
                            </Grid>
                        )
                    }
                    <Grid item xs={12}>
                        <TextField
                            name="treatment"
                            label="If your dog became ill, injured, or needed expensive medical treatment what would you do?*"
                            id='treatment'
                            defaultValue=""
                            placeholder='If your dog became ill, injured, or needed expensive medical treatment what would you do?'
                            variant="outlined"
                            fullWidth={true}
                            onChange={handleChange}
                            inputProps={{
                                "aria-required": 'true',
                            }}
                            inputRef={register({
                                required: true
                            })}
                        />
                        {errors.treatment &&
                            errors.treatment.type ===
                            'required' && (
                                <Alert severity='error' id='treatmentError'>Required</Alert>
                            )}
                    </Grid>
                    <Grid item xs={12} className={classes.alignLeft} >
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Do you agree to contact Paws on Parole if you encounter any issues with your dog you cannot resolve?*</FormLabel>
                            <RadioGroup aria-label="Do you agree to contact Paws on Parole if you encounter any issues with your dog you cannot resolve?"
                                name="contactPOP"
                                onChange={handleChange}
                                row={true}
                            >
                                <FormControlLabel value="Yes" control={
                                    <Radio color="primary"
                                        inputProps={{
                                            "aria-required": 'true',
                                        }}
                                        inputRef={register({
                                            required: true
                                        })} />} label="Yes" />
                                <FormControlLabel value="No" control={
                                    <Radio color="primary"
                                        inputProps={{
                                            "aria-required": 'true',
                                        }}
                                        inputRef={register({
                                            required: true
                                        })} />} label="No" />
                            </RadioGroup>
                        </FormControl>
                        {errors.contactPOP &&
                            errors.contactPOP.type ===
                            'required' && (
                                <Alert severity='error' id='contactPOPError'>Required</Alert>
                            )}
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="hear"
                            label="How did you hear about Paws on Parole?"
                            id='hear'
                            defaultValue=""
                            placeholder='How did you hear about Paws on Parole?'
                            variant="outlined"
                            fullWidth={true}
                            onChange={handleChange}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            name="other"
                            label="Is there anything else we should know?"
                            id='other'
                            multiline
                            rows={4}
                            defaultValue=""
                            placeholder='Is there anything else we should know?'
                            variant="outlined"
                            fullWidth={true}
                            onChange={handleChange}
                        />
                    </Grid>

                            */}


                    <Grid item xs={12}>
                        <div className={classes.root}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit">
                                Submit
                                </Button>
                        </div>
                    </Grid>

                    {
                        submitStatus === 200 && (
                            <Snackbar open={feedbackOpen} autoHideDuration={6000} onClose={handleFeedbackClose}>
                                <FeedbackAlert onClose={handleFeedbackClose} severity="success">
                                    Thank you, we've received your application!
                                </FeedbackAlert>
                            </Snackbar>
                        )
                    }
                    {
                        submitStatus !== null && submitStatus !== 200 && (
                            <Snackbar open={feedbackOpen} autoHideDuration={6000} onClose={handleFeedbackClose}>
                                <FeedbackAlert onClose={handleFeedbackClose} severity="error">
                                    Oops, looks like we ran into a problem! Please try again.
                                </FeedbackAlert>
                            </Snackbar>
                        )
                    }


                </Grid>
            </FormGroup>
        </ form >

    )
}