import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link'
import { photos } from './happyEndingsPhotos'
import PhotoGallery from '../../components/PhotoGallery'


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(4),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        paddingLeft: '15%',
        paddingRight: '15%',
        [theme.breakpoints.up('md')]: {
            paddingLeft: '20%',
            paddingRight: '20%',
        },
    },
}));


export default function HappyEndings(props) {
    const classes = useStyles();

    return (
        <Grid container spacing={0}>
            <Grid item xs={12}>
                <Paper square={true} elevation={0} className={classes.paper}>
                    <Typography variant="h4" component="h1" color="primary">
                        Happy Endings
                    </Typography>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper square={true} elevation={0} className={classes.paper}>
                    <Typography variant="body1" component="p">
                        We love updates from our Paws on Parole families!
                    </Typography>
                    <Typography variant="body1" component="p">
                        Please send your pictures and notes
                         to <Link href="mailto:heh@alachuacounty.us" title="Please send your pictures and notes
                         to this email">heh@alachuacounty.us </Link>
                    </Typography>
                    <Typography variant="caption">
                        Click on any picture to see full size or click on the first picture then click on it to scroll through all pictures.
                    </Typography>
                </Paper>
                <Paper square={true} elevation={0} className={classes.paper}>
                    <PhotoGallery photos={photos} />
                </Paper>
            </Grid>
        </Grid>
    )
}