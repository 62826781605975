import React from "react";
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography'
import LinkWithCaption from '../../components/LinkWithCaption'
import ImageWithCaption from '../../components/ImageWithCaption'
import thorImage from '../../img/Thor.JPG';

import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(4),
        textAlign: 'left',
        color: theme.palette.text.secondary,
        paddingLeft: '15%',
        paddingRight: '15%',
        [theme.breakpoints.up('md')]: {
            paddingLeft: '20%',
            paddingRight: '20%',
        },
    },
}));

const linkInfo = {
    trainer: [
        { title: "CCPDT", link: "https://www.ccpdt.org/?option=com_certificants&Itemid=102", caption: "Find a Certified Professional Dog Trainer in your area Certification Council for Professional Dog Trainers" },
        { title: "Pet Professional Guild Trainer Search", link: "https://www.petprofessionalguild.com/Zip-Code-Search", caption: "Find a Professional Dog Trainer committed to using only Positive Reinforcement based methods" },
    ],
    trainingVids: [
        { title: "Kikopup Videos", link: "https://www.youtube.com/user/kikopup", caption: "Emily Larlham shows you just how much fun Dog Training can be! Loads of great, short, fun Videos" },
        { title: "Donna Hill's Videos", link: "http://dogvideoindex.blogspot.com/", caption: "Dog Trainer Donna Hill's shares some great simple Training Videos." },
        { title: "All Dogs go to Kevin Videos", link: "https://www.youtube.com/channel/UCFE9vxI1W0bxh0aPu8Ms7Ww", caption: "Kevin Duggan shares his Training Videos" },
        { title: "Service Dog Training Videos", link: "http://viassistancedogs.blogspot.com/2009/01/index-to-blog-topics.html", caption: "YES! You can train your own Service Dog" },
        { title: "Victoria Stilwell Positively", link: "https://positively.com/", caption: "Victoria Stilwell has lots of Training Videos and other Positive Training info." },
        { title: "Dog Star Daily", link: "https://www.dogstardaily.com/", caption: "Dr. Ian Dunbar's awesome site - tons of free Positive Training info and Free Downloads" },
        { title: "Clicker Training", link: "https://www.clickertraining.com/", caption: "Interested in Clicker Training? Check out Karen Pryor's site." },
        { title: "Ultimate Puppy", link: "https://ultimatepuppy.com/how-dogs-learn/", caption: "Get ready to Raise the Perfect Puppy" },
        { title: "ASPCA Virtual Pet Behaviorist", link: "https://www.aspca.org/pet-care/dog-care/common-dog-behavior-issues", caption: "Lots of Positive Training & Behavior Articles" },
    ],
    behavior: [
        { title: "Fearful Dogs", link: "https://fearfuldogs.com/", caption: "Is your Dog Fearful or Fear aggressive?" },
        { title: "Care for Reactive Dogs", link: "http://careforreactivedogs.com/start-here/", caption: "Got a Leash Reactive Dog?" },
    ],
    miscellaneous: [
        { title: "AKC CGC", link: "https://www.akc.org/products-services/training-programs/canine-good-citizen/", caption: "What is a Canine Good Citizen?" },
        { title: "AVSAB", link: "https://avsab.org/resources/position-statements/", caption: "The American Veterinary Society of Animal Behavior Position Statement on Punishment, Dominance Theory, Puppy Socialization, and Choosing a Trainer" },
        { title: "How Will You Decide?", link: "http://www.dogsportmagazine.com/wp-content/uploads/2009/07/The%20Problem%20With%20Shock.pdf", caption: "Thinking about trying a shock collar?" },
        { title: "Training Methods and Training Tools", link: "https://unexamineddog.com/2012/07/15/hey-have-you-heard-the-one-about-climate-change-and-dog-training/", caption: 'This is a "must read" if you are confused about' },
        { title: "Dog Food Advisor", link: "https://www.dogfoodadvisor.com/", caption: "How does your dog food rate? Dog foods are rated from 1 to 5 stars. You just might be surprised!" },
        { title: "Pet Friendly Apartments", link: "https://gatorrentals.com/pet-friendly/", caption: "Looking for Pet Friendly Housing in the Gainesville Area?" },
        { title: "1-800-PetMeds", link: "https://www.1800petmeds.com/", caption: "Order your pets medication on line" },
    ]
}

export default function LinksPage() {
    const classes = useStyles();

    return (
        //align the container center: size 6
        //align left the content (headers, links, blurbs)
        //indent the links and content?

        //Link with Caption Component
        //create a json object w/ all data
        //iterate through data by section
        //conditionally render a description if available

        //grid
        //grid item xs 12 md 6
        //Page Title
        /* Section / header (repeat)
            links w/ captions 
        */


        <Grid container spacing={0}>
            <Grid item xs={12} md={2}></Grid> {/*placeholder*/}
            <Grid item xs={12} md={8}>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Paper elevation={0} className={classes.paper}>
                            <Typography variant="h4" component="h1" color="primary" >
                                {'Check Out Some of our Favorite Links'} {/*Links and Resources??? */}
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper elevation={0} className={classes.paper}>
                            <Typography variant="h5" component="h2" color="primary" paragraph={true} >
                                {'FIND A TRAINER'}
                            </Typography>
                            {
                                linkInfo.trainer.map((item, index) => (
                                    <LinkWithCaption key={index} link={item.link} title={item.title} caption={item.caption} />
                                ))
                            }
                        </Paper>

                    </Grid>
                    <Grid item xs={12}>
                        <Paper elevation={0} className={classes.paper}>
                            <Typography variant="h5" component="h2" color="primary" paragraph={true}>
                                {'TRAINING VIDEOS & ARTICLES'}
                                </Typography>
                                {
                                    linkInfo.trainingVids.map((item, index) => (
                                        <LinkWithCaption key={index} link={item.link} title={item.title} caption={item.caption} />
                                    ))
                                }
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper elevation={0} className={classes.paper}>
                                <Typography variant="h5" component="h2" color="primary" paragraph={true}>
                                    {'BEHAVIOR MODIFICATION'}
                                </Typography>
                                {
                                    linkInfo.behavior.map((item, index) => (
                                        <LinkWithCaption key={index} link={item.link} title={item.title} caption={item.caption} />
                                    ))
                                }
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper elevation={0} className={classes.paper}>
                                <Typography variant="h5" component="h2" color="primary" paragraph={true}>
                                    {'MISCELLANEOUS'}
                                </Typography>
                                {
                                    linkInfo.miscellaneous.map((item, index) => (
                                        <LinkWithCaption key={index} link={item.link} title={item.title} caption={item.caption} />
                                    ))
                                }
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper elevation={0}  textAlign='center'>
                                <Typography paragraph={true}>
                                    <ImageWithCaption image={thorImage} title={''} caption={'Thor from Academy 21 "Avengrrrs"'} />
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            <Grid item xs={12} md={2}></Grid> {/*placeholder*/}
        </Grid>
    );
};