import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import RecentComment from './recentComment';
import GuestForm from './form';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        background: theme.palette.primary.light,
        color: theme.palette.primary.main,
        padding: theme.spacing(4),
        textAlign: 'center',
        paddingLeft: '15%',
        paddingRight: '15%',
        [theme.breakpoints.up('md')]: {
            paddingLeft: '20%',
            paddingRight: '20%',
        },
    }
}));

export default function GuestBook() {

    const classes = useStyles();

    return (
        <Paper square={true} elevation={0} className={classes.paper} >
            <Typography variant="h5" component="h2" color="primary" style={{ textTransform: 'uppercase', marginBottom: '16px' }}>
                GuestBook
            </Typography>
            <Typography variant="body1" paragraph={true}>
                Thank-you so much for visiting our website! We love feedback so please take a moment to sign our Guestbook.
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} style={{ position: 'relative' }}>
                    <Typography variant="h6" component="h3" style={{ textTransform: 'uppercase', marginBottom: '16px' }}>
                        Most Recent Comment
                    </Typography>
                    <RecentComment></RecentComment>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="h6" component="h3" style={{ textTransform: 'uppercase', marginBottom: '16px' }}>
                        Sign Our GuestBook
                    </Typography>
                    <GuestForm withContact={false}></GuestForm>
                </Grid>
            </Grid>
        </Paper>
    )
}
