import React from "react";
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography'

import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(4),
        textAlign: 'center',
        //color: theme.palette.text.primary,
        paddingLeft: '15%',
        paddingRight: '15%',
        [theme.breakpoints.up('md')]: {
            paddingLeft: '20%',
            paddingRight: '20%',
        },
    },
    
}));



export default function ContentSection(props) {
    const classes = useStyles();

    var color;

    if(props.className === 'important') {
        color = 'error';
    } else {
        color = 'primary';
    }

    return (
       <Grid item xs={12} md={12}>
       <Paper square={true} elevation={0} className={classes.paper}>
        
           <Typography variant="h6" component="h2" align="left" color={color}>
               {props.title}
           </Typography>
           <Typography variant="body1" component="p" align="left" color={color}>
               {props.content}
           </Typography>
       </Paper>
   </Grid>
    )
}