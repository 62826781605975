import React from "react";
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography'
import ImageWithCaption from '../../components/ImageWithCaption'
import frankieImage from '../../img/Frankie-in-Tunnel.jpg';
import froggyAndSheriffImage from '../../img/Froggy-and-the-Sheriff.jpg';
import graceImage from '../../img/Grace.jpg';
import indyImage from '../../img/Indy.jpg';
import danaImage from '../../img/Dana-begs.jpg';

import { makeStyles } from '@material-ui/styles'


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(4),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        paddingLeft: '15%',
        paddingRight: '15%',
        [theme.breakpoints.up('md')]: {
            paddingLeft: '20%',
            paddingRight: '20%',
        },
    },
}));
const pawsOnParoleBlurb = {
    "blurb1": (<><p> <Typography variant="h6" component="span"> <strong>Paws on Parole </strong> </Typography> is a partnership program between Alachua County Animal Services,
    the Florida Department of Corrections Gainesville Work Camp, and the Alachua County Sheriffs Office.
    The program’s main goal is to increase the adoption and retention rates of selected dogs at the Alachua County Animal Shelter.
In addition, the program provides experience and education in dog care for inmates.</p>

        <p>The dogs are selected by shelter personnel and volunteer trainers, who test the dogs
        around other dogs, cats, and people. Selected dogs are matched with inmates at the Work Camp.
Under the supervision of volunteer professional trainers the dogs and their handlers spend eight weeks learning basic obedience.</p>

        <p>Inmates learn about daily dog care (including feeding, grooming, and routine health care) as well as positive training techniques.
        They learn to train the dogs to the standards of the American Kennel Club’s Canine Good Citizens (CGC) program. Equally important,
the dogs are socialized around a variety of people and other dogs during this period.</p></>),

    "blurb2": (<><p>Paws on Parole dogs are eligible for adoption after the eight-week training period, although potential adopters can submit
    applications before the training period ends. ACAS personnel and the Paws on Parole trainers carefully screen all adoption applications
    to ensure the right match between dog and person. The dogs go home with their new families the same day they pass their CGC test
and graduate from the program.</p>

        <p>Like all dogs adopted from ACAS, the Paws on Parole graduates are fully vaccinated, micro chipped, and spayed or neutered. </p>

        <p>Paws on Parole also includes an Aftercare Network, a group of volunteers willing to work with adopting families and their dogs to
        help integrate and keep the dog in the new home. The adopting families are encouraged to continue the dogs training and to take the
CGC test with their dog in order to receive an official CGC Certificate from the American Kennel Club. </p></>),

    "blurb3": (<><p>Our 100th graduate, Froggy, got to do a little schmoozin' with Sheriff
Sadie Darnell when she officiated at the 17th Academy Graduation. </p></>)
};



const info = {
    "frankie": {
        "img": frankieImage,
        "title": "",
        "caption": (<p>'Frankie' Academy 2 </p>)
    },
    "grace": {
        "img": graceImage,
        "title": "",
        "caption": (<p>'Grace' Academy 3 </p>)
    },
    "froggy": {
        "img": froggyAndSheriffImage,
        "title": "Froggy Meets the Sheriff",
        "caption": pawsOnParoleBlurb.blurb3
    },
    "dana": {
        "img": danaImage,
        "title": "",
        "caption": (<p>Dana from Academy 4 </p>)
    },
    "indy": {
        "img": indyImage,
        "title": '',
        "caption": (<p> Indy from Academy 11 "Travel and Leashes" </p>)
    }
};

export default function AboutPage() {

    const classes = useStyles();

    return (
        <Grid container spacing={0}>
            <Grid item xs={12}>
                <Paper elevation={0} className={classes.paper}>
                    <Typography variant="h4" component="h1" color="primary" >
                        About Us
                    </Typography>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <ImageWithCaption image={info.frankie.img} title={info.frankie.title} caption={info.frankie.caption} />
                <Paper square={true} elevation={0} className={classes.paper}>
                    <Typography variant="body1" component="p" color="primary" align="left" paragraph={true}>
                        {pawsOnParoleBlurb.blurb1}
                    </Typography>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <ImageWithCaption image={info.grace.img} title={info.grace.title} caption={info.grace.caption} />
                <Paper square={true} elevation={0} className={classes.paper}>
                    <Typography variant="body1" component="p" color="primary" align="left" paragraph={true}>
                        {pawsOnParoleBlurb.blurb2}
                    </Typography>
                </Paper>
            </Grid>
            <Grid item xs={12} md={12}>
                <Paper square={true} elevation={0} className={classes.paper}>
                    <ImageWithCaption image={info.froggy.img} title={info.froggy.title} caption={info.froggy.caption} />
                </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
                <Paper square={true} elevation={0} className={classes.paper}>
                    <ImageWithCaption image={info.dana.img} title={info.dana.title} caption={info.dana.caption} />
                </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
                <Paper square={true} elevation={0} className={classes.paper}>
                    <ImageWithCaption image={info.indy.img} title={info.indy.title} caption={info.indy.caption} />
                </Paper>
            </Grid>
        </Grid>


    );
};
