import React from "react";
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link';
//import { makeStyles } from '@material-ui/styles'

/*const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    }
}));*/

export default function LinkWithCaption(props) {

    //const classes = useStyles();

    return (
        <Grid container spacing={0}>
            <Grid item xs={12}>
                {props.caption !== '' && (
                    <Typography variant="body1" component="p" align="left">
                        {props.caption}
                    </Typography>
                )}
                <Typography variant="h6" component="h3" color="primary" align="left" paragraph={true}>
                    <Link href={props.link} target="_blank" color="inherit" underline="always" title={props.title}>
                        {props.title}
                    </Link>
                </Typography>    
            </Grid>
        </Grid >

    );
}
